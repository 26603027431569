import { IPFQuotesStore } from './interface';

export const PF_QUOTES_STORE = {
  NAME: 'Premium finance Quotes Store',
  ANONYMOUS_ACTION: 'premium finance quotes anonymous action',
  SET_LOADING_STATE_FOR_PF_QUOTE_AGREEMENT: 'SET_LOADING_STATE_FOR_PF_QUOTE_AGREEMENT',
  UPDATE_PF_QUOTES_STORE_BY_KEY: 'UPDATE_PF_QUOTES_STORE_BY_KEY',
  CLEAR_PF_QUOTES: 'CLEAR_PF_QUOTES',
};

export const PF_QUOTE_STORE_KEYS: { [key: string]: keyof IPFQuotesStore } = {
  SELECTED_PF_QUOTE: 'selectedPFQuote',
  LOADING_PROVIDER_ID: 'loadingProviderId',
};

export const PF_QUOTE_AGREEMENT_MESSAGE = {
  DOWNLOADING: 'Downloading Quote Agreement.',
  SUCCESS: 'Downloaded Quote Agreement!',
  ERROR: 'An error occurred while downloading quote agreement, please try again!',
};

export const PF_QUOTE_AGREEMENT_FILE_NAME = 'premium-finance-quote-agreement';
