import { POPUP_TYPE } from '../../globalConstants';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import AdditionalInterestModal from '../additionalInterestModal/additionalInterestModal';
import CancelRequestToBindModal from '../cancelRequestToBindModal/cancelRequestToBindModal';
import DeclinedReasonsModal from '../declinedReasonsModal/declinedReasonsModal';
import EffectiveDateModal from '../effectiveDateModalV2/effectiveDateModal';
import FailedReasonsModal from '../failedReasonsModal/failedReasonsModal';
import GenerateReport from '../generateReport/generateReport';
import IsuBlueLightSpecialModal from '../isuBlueLightSpecialModal/isuBlueLightSpecialModal';
import MarkAsBoundModal from '../markAsBoundModalV2/markAsBoundModal';
import ApplicationDocumentCenterModal from '../modals/applicationDocumentCenter/applicationDocumentCenter';
import BindUWStatement from '../modals/bindUWStatement/bindUWStatement';
import ContactUsModal from '../modals/contactUs/contactUs';
import DeleteCarrierModal from '../modals/deleteCarrier/deleteCarrier';
import DownloadAcordModal from '../modals/downloadAcord/downloadAcord';
import EditBindActionsModal from '../modals/editBindActions/editBindActions';
import Modifiers from '../modals/modifiers/modifiers';
import NewQuoteModal from '../modals/newQuote/newQuote';
import NewQuoteModalV2 from '../modals/newQuoteV2/newQuote';
import PaymentFeeBreakUp from '../modals/paymentFeeBreakUp/paymentFeeBreakUp';
import PaymentIframe from '../modals/paymentIframe/paymentIframe';
import QuoteComparison from '../modals/quoteComparison/quoteComparison';
import { ScheduleDemoModal } from '../modals/scheduleDemo/scheduleDemo';
import OwnerOfficerDetailsModal from '../ownerOfficerDetailsModal/ownerOfficerDetailsModal';
import PFQuoteFailedReasonsModal from '../pfQuoteFailedReasonsModal/pfQuoteFailedReasonsModal';
import PolicyDeclinedReasonsModal from '../policyDeclinedReasonsModal/policyDeclinedReasonsModal';
import ReferQuoteModal from '../referQuoteModal/referQuoteModal';
import RequestToBindModal from '../requestToBindModal/requestToBindModal';
import RequestToBindNotesModal from '../requestToBindNotesModal/requestToBindNotesModal';
import { ScheduleReport } from '../scheduleReport/scheduleReport';
import TimeoutReasonsModal from '../timeoutReasonsModal/timeoutReasonsModal';

const Popups = () => {
  const { popupType } = useCFModalStore();

  return (
    <>
      {popupType === POPUP_TYPE.NEW_QUOTE && <NewQuoteModal />}
      {popupType === POPUP_TYPE.NEW_QUOTE_V2 && <NewQuoteModalV2 />}
      {popupType === POPUP_TYPE.CONTACT_US && <ContactUsModal />}
      {popupType === POPUP_TYPE.ISU_BLUELIGHT_SPECIAL && <IsuBlueLightSpecialModal />}
      {popupType === POPUP_TYPE.FAILED_REASONS && <FailedReasonsModal />}
      {popupType === POPUP_TYPE.PF_QUOTE_FAILED_REASONS && <PFQuoteFailedReasonsModal />}
      {popupType === POPUP_TYPE.TIMEOUT_REASONS && <TimeoutReasonsModal />}
      {popupType === POPUP_TYPE.DECLINED_REASONS && <DeclinedReasonsModal />}
      {popupType === POPUP_TYPE.POLICY_DECLINED_REASONS && <PolicyDeclinedReasonsModal />}
      {popupType === POPUP_TYPE.REFER_QUOTE && <ReferQuoteModal />}
      {popupType === POPUP_TYPE.REQUEST_TO_BIND && <RequestToBindModal />}
      {popupType === POPUP_TYPE.CANCEL_REQUEST_TO_BIND && <CancelRequestToBindModal />}
      {popupType === POPUP_TYPE.REQUEST_TO_BIND_NOTES && <RequestToBindNotesModal />}
      {popupType === POPUP_TYPE.MARK_AS_BOUND_MODAL && <MarkAsBoundModal />}
      {popupType === POPUP_TYPE.PAST_EFFECTIVE_DATE_MODAL && <EffectiveDateModal />}
      {popupType === POPUP_TYPE.OWNER_OFFICER_DETAILS_MODAL && <OwnerOfficerDetailsModal />}
      {popupType === POPUP_TYPE.ADDITIONAL_INTEREST_MODAL && <AdditionalInterestModal />}
      {popupType === POPUP_TYPE.EDIT_BIND_ACTIONS_MODAL && <EditBindActionsModal />}
      {popupType === POPUP_TYPE.DOWNLOAD_ACORD_MODAL && <DownloadAcordModal />}
      {popupType === POPUP_TYPE.APPLICATION_DOCUMENT_CENTER_MODAL && <ApplicationDocumentCenterModal />}
      {popupType === POPUP_TYPE.DELETE_CARRIER_FROM_APPLICATION && <DeleteCarrierModal />}
      {popupType === POPUP_TYPE.MODIFIERS_MODAL && <Modifiers />}
      {popupType === POPUP_TYPE.GENERATE_REPORT_MODAL && <GenerateReport />}
      {popupType === POPUP_TYPE.SCHEDULE_REPORT_MODAL && <ScheduleReport />}
      {popupType === POPUP_TYPE.PAYMENT_FEE_BREAKUP_MODAL && <PaymentFeeBreakUp />}
      {popupType === POPUP_TYPE.PAYMENT_IFRAME_MODAL && <PaymentIframe />}
      {popupType === POPUP_TYPE.SCHEDULE_DEMO_MODAL && <ScheduleDemoModal />}
      {popupType === POPUP_TYPE.PAYMENT_UW_STATEMENT_MODAL && <BindUWStatement />}
      {popupType === POPUP_TYPE.QUOTE_COMPARISON_MODAL && <QuoteComparison />}
    </>
  );
};

export default Popups;
