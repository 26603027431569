import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  downloadPFQuoteAgreement as requestDownloadPFQuoteAgreement,
  eSignPFQuote,
} from '../../externalServices/V2/pfApplication';
import { errorV2, loading, success } from '../../ui-core/Notification';
import { downloadFileFromBase64 } from '../../utils/downloadFileFromBase64';
import { useConfigurationStore } from '../configuration/store';
import { PF_QUOTE_MESSAGES } from '../pfQuoteCardBottomSection/constants';
import {
  PF_QUOTE_AGREEMENT_FILE_NAME,
  PF_QUOTE_AGREEMENT_MESSAGE,
  PF_QUOTE_STORE_KEYS,
  PF_QUOTES_STORE,
} from './constants';
import { IPFQuotesStore } from './interface';

export const usePFQuotesStore = create<IPFQuotesStore>()(
  devtools(
    (set, get) => ({
      selectedPFQuote: undefined,
      loadingProviderId: undefined,
      loadingPFQuoteAgreementProviderIds: [],

      startESignPFQuoteProcess: async (pfQuote) => {
        const { updatePFQuotesByKey } = get();

        try {
          updatePFQuotesByKey(PF_QUOTE_STORE_KEYS.LOADING_PROVIDER_ID, pfQuote.pfProviderId);
          const data = await eSignPFQuote({ pfQuoteId: pfQuote.pfQuoteId });
          if (data.success) {
            success(data.message || PF_QUOTE_MESSAGES.ESIGN_SUCCESS);
          } else {
            errorV2(data.message || PF_QUOTE_MESSAGES.ESIGN_FAIL);
          }
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || PF_QUOTE_MESSAGES.ESIGN_FAIL);
        } finally {
          updatePFQuotesByKey(PF_QUOTE_STORE_KEYS.LOADING_PROVIDER_ID, undefined);
        }
      },

      downloadPFQuoteAgreement: async (pfQuote) => {
        const { userTheme } = useConfigurationStore.getState();

        const quoteAgreementMessage = loading(
          PF_QUOTE_AGREEMENT_MESSAGE.DOWNLOADING,
          60,
          userTheme?.colorScheme?.primary,
        );
        set(
          { loadingPFQuoteAgreementProviderIds: [...get().loadingPFQuoteAgreementProviderIds, pfQuote.pfProviderId!] },
          false,
          PF_QUOTES_STORE.SET_LOADING_STATE_FOR_PF_QUOTE_AGREEMENT,
        );

        try {
          const quoteAgreementResponse = await requestDownloadPFQuoteAgreement({ pfQuoteId: pfQuote.pfQuoteId });
          const { quoteDocument } = quoteAgreementResponse;

          downloadFileFromBase64(quoteDocument, PF_QUOTE_AGREEMENT_FILE_NAME);

          success(PF_QUOTE_AGREEMENT_MESSAGE.SUCCESS, 5);
        } catch (err: any) {
          errorV2(err?.[0]?.errorMessage || PF_QUOTE_AGREEMENT_MESSAGE.ERROR, 5);
        } finally {
          quoteAgreementMessage();
          set(
            {
              loadingPFQuoteAgreementProviderIds: get().loadingPFQuoteAgreementProviderIds.filter(
                (providerId) => providerId !== pfQuote.pfProviderId,
              ),
            },
            false,
            PF_QUOTES_STORE.SET_LOADING_STATE_FOR_PF_QUOTE_AGREEMENT,
          );
        }
      },

      updatePFQuotesByKey: async (storeKey: keyof IPFQuotesStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, PF_QUOTES_STORE.UPDATE_PF_QUOTES_STORE_BY_KEY),

      clearPFQuotes: async () =>
        set(
          {
            selectedPFQuote: undefined,
            loadingProviderId: undefined,
            loadingPFQuoteAgreementProviderIds: [],
          },
          false,
          PF_QUOTES_STORE.CLEAR_PF_QUOTES,
        ),
    }),

    { name: PF_QUOTES_STORE.NAME, anonymousActionType: PF_QUOTES_STORE.ANONYMOUS_ACTION },
  ),
);
