import { useQuotesStore } from '../../quotes/store';
import { useBindUWStatementStore } from './store';
import { BindUWStatementsWrapperStyled } from './styles';

export const getBindUWStatementData = () => {
  const { bindUWStatementData } = useBindUWStatementStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  return bindUWStatementData?.[selectedQuote?.carrierId ?? '']?.statementGroups?.map((statementGroup, index) => {
    return (
      <BindUWStatementsWrapperStyled key={`${statementGroup.heading?.statementText}-${index}`}>
        {statementGroup.heading?.statementText && <h3>{statementGroup.heading.statementText}</h3>}
        {statementGroup.statements?.length > 0 && (
          <ul>
            {statementGroup.statements.map((statement, statementIndex) => (
              <li
                key={`${statement}-${statementIndex}`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `${statement.statementText}`,
                }}
              />
            ))}
          </ul>
        )}
        {statementGroup.statementGroups?.map((subStatementGroup) => {
          return (
            <>
              {subStatementGroup.heading?.statementText && <h4>{subStatementGroup.heading.statementText}</h4>}
              {subStatementGroup.statements?.length > 0 && (
                <ul>
                  {subStatementGroup.statements.map((subStatement, subStatementIndex) => (
                    <>
                      {subStatement.statementText && (
                        <li
                          key={`${subStatement}-${subStatementIndex}`}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: `${subStatement.statementText}`,
                          }}
                        />
                      )}
                    </>
                  ))}
                </ul>
              )}
            </>
          );
        })}
      </BindUWStatementsWrapperStyled>
    );
  });
};
