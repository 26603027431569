import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { PREMIUM_FINANCE_SERVICE, PREMIUM_FINANCE_SERVICE_PARAMS } from './constants';

const axiosPremiumFinanceService = createInstance(PREMIUM_FINANCE_SERVICE_PARAMS);

defaultRequestInterceptor({ axiosInstance: axiosPremiumFinanceService, serviceName: PREMIUM_FINANCE_SERVICE });
defaultResponseInterceptor({ axiosInstance: axiosPremiumFinanceService, serviceName: PREMIUM_FINANCE_SERVICE });

export default methods(axiosPremiumFinanceService);
