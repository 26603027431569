import { formatPrice } from '../../helpers/Utils';
import {
  PFQuoteDetailAccordianNameStyled,
  PFQuoteDetailAccordianRowStyled,
  PFQuoteDetailAccordianValueStyled,
  PFQuoteDetailAccordionRowDividerStyled,
  PFQuoteDetailAccordionStyled,
} from '../pfQuoteDetails/styles';
import { usePFQuotesStore } from '../pfQuotes/store';
import { IPFQuotePremiumFinanceDetailsProps } from './interface';

const PFQuotePremiumFinanceDetails = (pfQuotePremiumFinanceDetailsProps: IPFQuotePremiumFinanceDetailsProps) => {
  const { selectedPFQuote } = usePFQuotesStore();

  const {
    downPaymentAmount = 0,
    amountFinanced = 0,
    financeCharge = 0,
    totalPayments = 0,
  } = selectedPFQuote?.financeDetails || {};

  return (
    <PFQuoteDetailAccordionStyled id='pf_quote_premium_details' {...pfQuotePremiumFinanceDetailsProps}>
      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Down Payment Amount</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          ${formatPrice(downPaymentAmount?.toString())}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Financed Amount</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          ${formatPrice(amountFinanced?.toString())}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Finance Charge</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>${formatPrice(financeCharge?.toString())}</PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Total Payments</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>${formatPrice(totalPayments?.toString())}</PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />
    </PFQuoteDetailAccordionStyled>
  );
};

export default PFQuotePremiumFinanceDetails;
