import { SESSION_STORAGE_KEYS } from '../globalConstants';
import { useProfileV2Store } from '../pages/v2/profile/store';
import { getFromSessionStorage } from './getFromSessionStorage';

export const getSelectedAgentProfileId = () => {
  const { selectedUserProfile } = useProfileV2Store.getState();
  const sessionStorageAgentProfileId = getFromSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID);
  const { agentProfileId = '' } = selectedUserProfile || {};

  const selectedAgentProfileId = sessionStorageAgentProfileId || agentProfileId || '';
  return selectedAgentProfileId;
};
