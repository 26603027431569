import { IDependencyInfo } from '@coverforce-platform/cf-common-api-model';

import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion, IRenderDependentQuestion } from '../applicationQuestion/interface';
import CfQuestionWithStatus from '../cfQuestionWithStatusV2/cfQuestionWithStatus';
import { isChildQuestionRender } from './utils';

const DependentQuestion = ({
  question,
  getFieldValue,
  allQuestions,
  form,
  showStatus,
  prefixDataIndex,
  onChange,
  hidden = false,
  disabled = false,
}: IRenderDependentQuestion) => {
  /**
   * Scenario
   * 1. suppose we have multi select dropdown question
   *  now user select option 1 then need to render question 1
   *  now user select option 2 then need to render question 1,3
   *
   * Now in this case question 1 is already render so now only we need to render 3.
   * For this reason we maintain the renderedChildQuestionIds array to keep track of rendered child questions under the current iterating main question.
   */
  const renderedChildQuestionIds: string[] = [];
  const getChildQuestion = (dependentQuestion: IDependencyInfo, parentQuestion: IApplicationQuestion) => {
    return dependentQuestion.childQuestionIds?.map((id) => {
      const filteredQuestion = allQuestions?.find((item: IApplicationQuestion) => item.dataIndex === id);
      if (!filteredQuestion) {
        return;
      }

      const hasRendered = filteredQuestion?.childToParentQuestionsMap
        ? // Check question needs to be render or not
          filteredQuestion?.childToParentQuestionsMap?.find((el) => el.parentQuestionId === parentQuestion.dataIndex)
            ?.hasRendered
        : true;

      const isAlreadyRenderedQuestionUnderSameParent =
        renderedChildQuestionIds.indexOf(filteredQuestion?.dataIndex) !== -1;

      renderedChildQuestionIds.push(filteredQuestion?.dataIndex);

      return (
        hasRendered &&
        // Check is it already render under the same parent question
        !isAlreadyRenderedQuestionUnderSameParent &&
        (showStatus ? (
          <CfQuestionWithStatus
            key={filteredQuestion.dataIndex}
            question={{ ...filteredQuestion, dataIndex: `${filteredQuestion.dataIndex}`, isChildRender: true }}
            questions={allQuestions}
            form={form}
            prefixDataIndex={prefixDataIndex}
            disabled={disabled}
            onChange={onChange}
          />
        ) : (
          <ApplicationQuestion
            key={`form-updater-dependent-item-${id}`}
            question={{ ...filteredQuestion, dataIndex: filteredQuestion.dataIndex, isChildRender: true }}
            allQuestions={allQuestions}
            form={form}
            hidden={hidden}
            disabled={disabled}
            onChange={onChange}
          />
        ))
      );
    });
  };

  // check parent question answered or not, if answered then check condition for dependency question and if its satisfied then return the question
  const Elements: any = question.dependentQuestions?.map((dependentQuestion) => {
    if (isChildQuestionRender({ question, getFieldValue, dependentQuestion, prefixDataIndex, form })) {
      return getChildQuestion(dependentQuestion, question);
    } else {
      return null;
    }
  });

  return Elements || <></>;
};

export default DependentQuestion;
