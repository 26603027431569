import { useEffect } from 'react';

import { formatPrice } from '../../../helpers/Utils';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { errorV2, success } from '../../../ui-core/Notification';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { getAllSuccessfulQuotes } from '../../../utils/quotes/quotes';
import { QUOTE_COMPARISON_MODAL_DETAILS, QUOTE_COMPARISON_MODAL_STORE_KEYS } from './constants';
import { useQuoteComparisonModalStore } from './store';
import {
  QuoteComparisonCarrierStyled,
  QuoteComparisonCheckboxGroupStyled,
  QuoteComparisonCheckboxItemStyled,
  QuoteComparisonCheckboxLabelWrapperStyled,
  QuoteComparisonCheckboxRowStyled,
  QuoteComparisonCheckboxStyled,
  QuoteComparisonColStyled,
  QuoteComparisonModalStyled,
  QuoteComparisonPremiumStyled,
  QuoteComparisonTextStyled,
  QuoteComparisonTextWrapperStyled,
} from './styles';

const QuoteComparison = () => {
  const { clearCfModal } = useCFModalStore();
  const { selectedCarriersInfo } = useApplicationStore();
  const {
    isQuoteComparisonLoading,
    quoteComparisonCarriers,
    compareCarrierQuotes,
    updateQuoteComparisonModalStoreByKey,
    clearQuoteComparisonStore,
  } = useQuoteComparisonModalStore();
  const successfulQuotes = getAllSuccessfulQuotes();

  useEffect(() => {
    return () => {
      clearQuoteComparisonStore();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuoteComparisonChange = (selectedCarriers: any) => {
    updateQuoteComparisonModalStoreByKey(QUOTE_COMPARISON_MODAL_STORE_KEYS.QUOTE_COMPARISON_CARRIERS, selectedCarriers);
  };

  const handleQuoteComparisonDownload = async () => {
    try {
      await compareCarrierQuotes();
      clearCfModal();
      success(QUOTE_COMPARISON_MODAL_DETAILS.SUCCESS);
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || QUOTE_COMPARISON_MODAL_DETAILS.ERROR);
    } finally {
      updateQuoteComparisonModalStoreByKey(QUOTE_COMPARISON_MODAL_STORE_KEYS.IS_QUOTE_COMPARISON_LOADING, false);
    }
  };

  return (
    <QuoteComparisonModalStyled
      title={QUOTE_COMPARISON_MODAL_DETAILS.TITLE}
      okText='Download'
      onOk={handleQuoteComparisonDownload}
      okButtonProps={{
        id: 'btn_download_quote_comparison_ok',
        loading: isQuoteComparisonLoading,
        disabled: quoteComparisonCarriers?.length <= 1 || quoteComparisonCarriers?.length > 4,
      }}
      cancelButtonProps={{ id: 'btn_download_quote_comparison_cancel', disabled: isQuoteComparisonLoading }}
      disableCloseIcon={isQuoteComparisonLoading}
      maskClosable={!isQuoteComparisonLoading}
    >
      <QuoteComparisonTextWrapperStyled>
        <QuoteComparisonTextStyled>{QUOTE_COMPARISON_MODAL_DETAILS.DESCRIPTION}</QuoteComparisonTextStyled>
      </QuoteComparisonTextWrapperStyled>
      <QuoteComparisonCheckboxGroupStyled onChange={handleQuoteComparisonChange}>
        <QuoteComparisonCheckboxRowStyled>
          {successfulQuotes.map((item) => {
            const { carrierId, totalAmount } = item;
            const itemText = selectedCarriersInfo.find(
              (carrierInfo) => carrierInfo?.carrierId === item?.carrierId,
            )?.carrierShortName;
            return (
              <QuoteComparisonColStyled
                key={carrierId}
                span={11}
                isChecked={quoteComparisonCarriers.includes(carrierId!)}
              >
                <QuoteComparisonCheckboxStyled id={`checkbox-item-${carrierId}`} value={carrierId}>
                  <QuoteComparisonCheckboxItemStyled>
                    <QuoteComparisonCheckboxLabelWrapperStyled>
                      <QuoteComparisonCarrierStyled>{itemText}</QuoteComparisonCarrierStyled>
                      <QuoteComparisonPremiumStyled>{`$${formatPrice(
                        totalAmount?.toString(),
                      )}`}</QuoteComparisonPremiumStyled>
                    </QuoteComparisonCheckboxLabelWrapperStyled>
                  </QuoteComparisonCheckboxItemStyled>
                </QuoteComparisonCheckboxStyled>
              </QuoteComparisonColStyled>
            );
          })}
        </QuoteComparisonCheckboxRowStyled>
      </QuoteComparisonCheckboxGroupStyled>
    </QuoteComparisonModalStyled>
  );
};

export default QuoteComparison;
