import { CloseOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo } from 'react';

import { BUTTON_TYPE } from '../../../globalConstants';
import CFButton from '../cfButton/cfButton';
import { ICFModal } from './interface';
import { useCFModalStore } from './store';
import { StyledCFModal } from './styles';

const CFModal = ({ children, closeIcon, data, onCancel, onOk, disableCloseIcon = false, ...rest }: ICFModal) => {
  const { isVisible, clearCfModal } = useCFModalStore();

  // Clear cfModal store on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearCfModal(), []);

  const handleClose = useCallback(
    (event) => {
      onCancel && onCancel(event, data);
      clearCfModal();
    },
    [clearCfModal, data, onCancel],
  );

  const handleOk = useCallback(
    (event) => {
      onOk && onOk(event, data);
    },
    [data, onOk],
  );

  const getCloseIcon = useMemo((): React.ReactNode => {
    return (
      closeIcon ?? (
        <CFButton
          id='mod_close'
          onClick={handleClose}
          buttonType={BUTTON_TYPE.ACTION}
          shape='circle'
          icon={<CloseOutlined />}
          disabled={disableCloseIcon}
        />
      )
    );
  }, [closeIcon, disableCloseIcon, handleClose]);

  return (
    <StyledCFModal
      open={isVisible}
      closeIcon={getCloseIcon}
      onOk={handleOk}
      onCancel={handleClose}
      disableCloseIcon={disableCloseIcon}
      destroyOnClose
      {...rest}
    >
      {children}
    </StyledCFModal>
  );
};

export default CFModal;
