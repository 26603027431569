import PFQuoteGeneralDetails from '../pfQuoteGeneralDetails/pfQuoteGeneralDetails';
import PFQuoteInstallmentDetails from '../pfQuoteInstallmentDetails/pfQuoteInstallmentDetails';
import PFQuotePremiumFinanceDetails from '../pfQuotePremiumFinanceDetails/pfQuotePremiumFinanceDetails';
import { IPFQuoteDetailAccordion } from './interface';

export const PF_QUOTE_DETAILS_DRAWER = 'Premium Finance Quote Details';

export const PF_QUOTE_DETAILS_SECTIONS_META_DATA = {
  GENERAL_DETAILS: { key: 'generalDetails', text: 'General Details' },
  FINANCE_DETAILS: { key: 'financeDetails', text: 'Premium Finance Details' },
  INSTALLMENT_DETAILS: { key: 'installmentDetails', text: 'Installment Details' },
};

export const DEFAULT_ACTIVE_KEYS = [
  PF_QUOTE_DETAILS_SECTIONS_META_DATA.GENERAL_DETAILS.key,
  PF_QUOTE_DETAILS_SECTIONS_META_DATA.FINANCE_DETAILS.key,
  PF_QUOTE_DETAILS_SECTIONS_META_DATA.INSTALLMENT_DETAILS.key,
];

export const PF_QUOTE_DETAIL_SECTIONS: IPFQuoteDetailAccordion[] = [
  {
    itemKey: PF_QUOTE_DETAILS_SECTIONS_META_DATA.GENERAL_DETAILS.key,
    heading: PF_QUOTE_DETAILS_SECTIONS_META_DATA.GENERAL_DETAILS.text,
    DetailSection: PFQuoteGeneralDetails,
    defaultActiveKey: DEFAULT_ACTIVE_KEYS,
  },
  {
    itemKey: PF_QUOTE_DETAILS_SECTIONS_META_DATA.FINANCE_DETAILS.key,
    heading: PF_QUOTE_DETAILS_SECTIONS_META_DATA.FINANCE_DETAILS.text,
    DetailSection: PFQuotePremiumFinanceDetails,
    defaultActiveKey: DEFAULT_ACTIVE_KEYS,
  },
  {
    itemKey: PF_QUOTE_DETAILS_SECTIONS_META_DATA.INSTALLMENT_DETAILS.key,
    heading: PF_QUOTE_DETAILS_SECTIONS_META_DATA.INSTALLMENT_DETAILS.text,
    DetailSection: PFQuoteInstallmentDetails,
    defaultActiveKey: DEFAULT_ACTIVE_KEYS,
  },
];
