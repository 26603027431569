import { ICreatePFQuoteInput } from '@coverforce-platform/cf-common-api-model';

import { usePFApplicationInfoQuestionsStore } from '../../../components/pfApplicationInfo/store';
import { removeUndefinedProperty } from '../../../helpers/Utils';

export const getPFApplicationPayload = (): ICreatePFQuoteInput => {
  // INFO: In future if multiple steps introduce then we will use this function for getting payload by using switch case
  const { getPFApplicationInfoQuestionsApiPayload } = usePFApplicationInfoQuestionsStore.getState();
  const applicationQuestionPayload = removeUndefinedProperty(getPFApplicationInfoQuestionsApiPayload());
  return applicationQuestionPayload;
};
