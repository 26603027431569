import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUserData, getUserProfilesByAgentId } from '../../../externalServices/V2/accountsV2';
import { updateAgent } from '../../../externalServices/V2/agents';
import { LOCAL_STORAGE_KEYS, LOCAL_STORAGE_VALUES, SESSION_STORAGE_KEYS } from '../../../globalConstants';
import { error } from '../../../ui-core/Notification';
import { getAuthId } from '../../../utils/getAuthId';
import { getFromLocalStorage } from '../../../utils/getFromLocalStorage';
import { getFromSessionStorage } from '../../../utils/getFromSessionStorage';
import { saveToSessionStorage } from '../../../utils/saveToSessionStorage';
import { logError } from '../../../utils/sentry';
import { getUpdatedUserPayload } from '../../../utils/teamManagement/team';
import { useRegistrationStore } from '../registration/store';
import { PROFILE_STORE_META_DATA } from './constant';
import { IEditProfile, IProfileV2Data, IProfileV2Store } from './interface';

export const useProfileV2Store = create<IProfileV2Store>()(
  devtools(
    (set, get) => ({
      appLoading: true,

      userData: undefined,
      userDataLoading: false,
      userDataError: null,

      // profileLoading: false,
      // profileData: undefined,
      // profileError: null,

      userProfilesError: null,
      userProfiles: undefined,

      selectedUserProfile: undefined,

      // fetchProfile: async (isEdit = false) => {
      //   try {
      //     set({ profileLoading: true });
      //     const profileData = await getAgentByUsername();
      //     set({ profileData: profileData?.agents?.[0], profileError: null }, false, 'fetchProfile');
      //   } catch (err: any) {
      //     if (isEdit) {
      //       set({ profileError: err }, false, 'fetchProfileError');
      //       error(err?.[0]?.errorMessage);
      //     } else {
      //       set({ profileData: undefined, profileError: err }, false, 'fetchProfileError');
      //     }
      //   } finally {
      //     set({ profileLoading: false }, false, 'fetchProfileLoading');
      //   }
      // },

      fetchUserData: async (fetchUserDataParam = { isLoginLayer: true }) => {
        try {
          set({ userDataLoading: true });
          const loggedInUserData = await getUserData({ authId: getAuthId() });
          set({ userData: loggedInUserData, userDataError: null }, false, PROFILE_STORE_META_DATA.FETCH_USER_DATA);
          return loggedInUserData;
        } catch (err: any) {
          if (fetchUserDataParam?.isLoginLayer) {
            set({ userData: undefined, userDataError: err }, false, PROFILE_STORE_META_DATA.FETCH_USER_DATA_ERROR);
            // TODO: Try and check whether we can improve this logic in future.
            return Promise.reject('No user data found');
          } else {
            set({ userDataError: err }, false, PROFILE_STORE_META_DATA.FETCH_USER_DATA_ERROR);
            error(err?.[0]?.errorMessage);
          }
        } finally {
          set({ appLoading: false, userDataLoading: false }, false, PROFILE_STORE_META_DATA.FETCH_USER_DATA_LOADING);
        }
      },

      fetchUserProfiles: async () => {
        const { agentId } = get().userData || {};
        const response = await getUserProfilesByAgentId({ agentId: agentId! });
        set(
          { userProfiles: response?.agentProfiles, userProfilesError: null },
          false,
          PROFILE_STORE_META_DATA.FETCH_USER_PROFILES,
        );

        // If no profiles found, reject the promise so that the caller can handle it and redirect to logout.
        if (response?.agentProfiles?.length === 0) {
          return Promise.reject('No profiles found');
        }

        return response?.agentProfiles;
      },

      fetchSelectedUserProfile: async (pageLoadUserProfiles) => {
        try {
          const { userProfiles } = get();
          const availableUserProfiles = pageLoadUserProfiles || userProfiles || [];
          const profileId = getFromSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID);

          let selectedUserProfile;

          if (getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_TYPE) === LOCAL_STORAGE_VALUES.SSO) {
            selectedUserProfile =
              availableUserProfiles?.find((profile) => profile?.agentProfileId === profileId) ??
              availableUserProfiles?.[0];
          } else {
            const { getLoginRegistrationConfigByClientAppName } = useRegistrationStore.getState();
            const clientAppName = getFromSessionStorage(SESSION_STORAGE_KEYS.CLIENT_APP);
            const loginRegConfig = await getLoginRegistrationConfigByClientAppName({
              clientAppName: clientAppName ?? 'cf',
            });
            if (profileId) {
              selectedUserProfile =
                availableUserProfiles?.find((profile) => profile?.agentProfileId === profileId) ??
                availableUserProfiles?.[0];
            } else {
              selectedUserProfile =
                availableUserProfiles?.find(
                  (profile) => profile?.agencyNetworkName === loginRegConfig?.agencyNetworkName,
                ) ?? availableUserProfiles?.[0];
            }
          }

          // This is just a safe check in case the above conditions goes into the fallback code and we set the first profile as default
          // This will setup a new profile in case the user has no profileId from above.
          saveToSessionStorage(SESSION_STORAGE_KEYS.PROFILE_ID, selectedUserProfile?.agentProfileId);
          !selectedUserProfile?.agentProfileId &&
            logError({ error: `Unable to find selected agentProfileId: ${JSON.stringify(selectedUserProfile)}` });

          set({ selectedUserProfile }, false, PROFILE_STORE_META_DATA.FETCH_SELECTED_USER_PROFILE);
          return selectedUserProfile;
        } catch (err: any) {
          set({ selectedUserProfile: undefined }, false, PROFILE_STORE_META_DATA.FETCH_SELECTED_USER_PROFILE_ERROR);
          error(err?.[0]?.errorMessage);
        }
      },

      clearProfile: () =>
        set(
          {
            userData: undefined,
            userDataLoading: false,
            userDataError: null,
            selectedUserProfile: undefined,
            appLoading: true,
            userProfilesError: null,
            userProfiles: undefined,
            // profileData: undefined,
            // profileError: null,
            // profileLoading: false,
          },
          false,
          'clearProfile',
        ),

      updateProfileByKey: (storeKey: keyof IProfileV2Data, value: any) =>
        set(() => ({ [storeKey]: value }), false, 'updateProfileByKey'),

      updateProfile: async (updatedAgent: IEditProfile) => {
        const updateAgentPayload = getUpdatedUserPayload(updatedAgent);

        await updateAgent(updateAgentPayload);
        get().fetchUserData({ isLoginLayer: false });
      },
    }),
    { anonymousActionType: 'profileV2' },
  ),
);
