import { DATE_FORMAT, POLICY_NAME_MAPPING } from '../../globalConstants';
import { formatDate } from '../../helpers/Utils';
import { usePFApplicationStore } from '../../pages/v2/pfApplication/store';
import { useConfigurationStore } from '../configuration/store';
import {
  PFQuoteDetailAccordianNameStyled,
  PFQuoteDetailAccordianRowStyled,
  PFQuoteDetailAccordianValueStyled,
  PFQuoteDetailAccordionRowDividerStyled,
  PFQuoteDetailAccordionStyled,
} from '../pfQuoteDetails/styles';
import { usePFQuotesStore } from '../pfQuotes/store';
import { IPFQuoteGeneralDetailsProps } from './interface';

const PFQuoteGeneralDetails = (pfQuoteGeneralDetailsProps: IPFQuoteGeneralDetailsProps) => {
  const { pfApplicationData } = usePFApplicationStore();
  const { allCarriersData } = useConfigurationStore();

  const { selectedPFQuote } = usePFQuotesStore();

  return (
    <PFQuoteDetailAccordionStyled id='pf_quote_premium_financed_general_details' {...pfQuoteGeneralDetailsProps}>
      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Provider</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>{selectedPFQuote?.pfProviderId}</PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Quote ID</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>{selectedPFQuote?.pfQuoteId}</PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Policy Type</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          {POLICY_NAME_MAPPING[pfApplicationData?.policyDetails?.policyType || '']}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Carrier</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          {allCarriersData[pfApplicationData?.policyDetails?.carrier || '']?.carrierShortName}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Effective Date</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          {formatDate(pfApplicationData?.policyDetails?.effectiveDate, DATE_FORMAT.DDMMMYYYY, false)}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Expiration Date</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          {formatDate(pfApplicationData?.policyDetails?.expirationDate, DATE_FORMAT.DDMMMYYYY, false)}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />
    </PFQuoteDetailAccordionStyled>
  );
};

export default PFQuoteGeneralDetails;
