import { useEffect } from 'react';

import { PF_QUOTE_STORE_KEYS } from '../pfQuotes/constants';
import { usePFQuotesStore } from '../pfQuotes/store';
import { PF_QUOTE_DETAIL_SECTIONS, PF_QUOTE_DETAILS_DRAWER } from './constants';
import { PFQuoteDetailsDrawerStyled, TitleStyled } from './styles';

export const PFQuoteDetails = () => {
  const { selectedPFQuote, updatePFQuotesByKey } = usePFQuotesStore();
  useEffect(() => {
    return () => {
      updatePFQuotesByKey(PF_QUOTE_STORE_KEYS.SELECTED_PF_QUOTE, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {selectedPFQuote && (
        <PFQuoteDetailsDrawerStyled
          id='pf-quote-details-drawer'
          title={<TitleStyled>{PF_QUOTE_DETAILS_DRAWER}</TitleStyled>}
        >
          {PF_QUOTE_DETAIL_SECTIONS.map((section) => {
            const { itemKey, heading, DetailSection, defaultActiveKey, bordered = false, ghost = true } = section;

            return (
              <DetailSection
                key={itemKey}
                itemKey={itemKey}
                header={heading}
                defaultActiveKey={defaultActiveKey}
                bordered={bordered}
                ghost={ghost}
              />
            );
          })}
        </PFQuoteDetailsDrawerStyled>
      )}
    </>
  );
};
