import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  createPFApplication as requestCreatePFApplication,
  fetchPFApplication,
} from '../../../externalServices/V2/pfApplication';
import { DEFAULT_API_ERROR_MESSAGE } from '../../../globalConstants';
import { PF_APPLICATION_STORE } from './constants';
import { IPFApplicationStore } from './interface';
import { getPFApplicationPayload } from './utils';

export const usePFApplicationStore = create<IPFApplicationStore>()(
  devtools(
    (set) => ({
      isReadOnlyPFApplication: false,
      isPFApplicationLoading: false,
      pfApplicationError: undefined,
      pfApplicationData: undefined,

      fetchPFApplication: async (pfApplicationId: string, loading = true) => {
        try {
          set({ isPFApplicationLoading: loading }, false, PF_APPLICATION_STORE.SET_PF_APPLICATION_LOADING);
          const pfApplicationData = await fetchPFApplication({ pfApplicationId });
          set(
            {
              pfApplicationData,
              isPFApplicationLoading: false,
              pfApplicationError: undefined,
            },
            false,
            PF_APPLICATION_STORE.GET_PF_APPLICATION,
          );
          return pfApplicationData;
        } catch (error: any) {
          const errorMessage = error?.[0]?.errorMessage || DEFAULT_API_ERROR_MESSAGE;
          set(
            { pfApplicationError: errorMessage, pfApplicationData: undefined, isPFApplicationLoading: false },
            false,
            PF_APPLICATION_STORE.GET_PF_APPLICATION,
          );
          return undefined;
        }
      },

      createPFApplication: async (isPFApplicationLoading = true) => {
        set({ isPFApplicationLoading }, false, PF_APPLICATION_STORE.SET_PF_APPLICATION_LOADING);
        const createPFApplicationPayload = getPFApplicationPayload();

        const pfApplicationData: any = await requestCreatePFApplication(createPFApplicationPayload);

        set({ pfApplicationData }, false, PF_APPLICATION_STORE.UPDATE_PF_APPLICATION);
        return pfApplicationData;
      },

      updatePFApplicationByKey: (storeKey: keyof IPFApplicationStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, PF_APPLICATION_STORE.UPDATE_PF_APPLICATION_STORE_BY_KEY),

      clearPFApplication: () =>
        set(
          {
            isReadOnlyPFApplication: false,
            pfApplicationError: undefined,
            isPFApplicationLoading: false,
            pfApplicationData: undefined,
          },
          false,
          PF_APPLICATION_STORE.CLEAR_PF_APPLICATION,
        ),
    }),

    { name: PF_APPLICATION_STORE.NAME, anonymousActionType: PF_APPLICATION_STORE.ANONYMOUS_ACTION_TYPE },
  ),
);
