import {
  IApplicationInfo,
  ICardPaymentInstrumentDetails,
  ICarrierInfo,
  ILiabilityLimitsInfo,
  ILocationInfo,
  IPaymentPlan,
  IPaymentPlanSelectedAdditionalDetail,
  IQuoteInfo,
} from '@coverforce-platform/cf-common-api-model';
import {
  ApplicationStatus,
  CardType,
  Carrier,
  PaymentIntegrationMethod,
  PaymentPlanType,
  QuotePaymentStatus,
  QuoteStatus,
} from '@coverforce-platform/cf-common-types';

import { APPLICATION_STORE_KEYS, APPLICATION_TABS } from '../../constants/applicationV2Constants';
import { POLICY_NAME_MAPPING, POPUP_TYPE, QUERY_PARAMS } from '../../globalConstants';
import { IAntdOptions } from '../../globalInterface';
import { OwnerOfficerMessageType } from '../../helpers/Constants';
import { formatDate, formatPrice, normalizeMoneyInput, removeDecimal } from '../../helpers/Utils';
import { IAccount } from '../../pages/v2/account/interface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { getLiabilityLimitsByPolicy } from '../../utils/application/application';
import { generateAPIErrorMessage } from '../../utils/generateAPIErrorMessage';
import { isEmpty } from '../../utils/lodash';
import {
  denormalizeCardExpiryDate,
  isValidCardExpiryDate,
  normalizeCardExpiryDate,
} from '../../utils/paymentCardExpiryDateUtil';
import { denormalizeCardNumber, isValidCardNumber } from '../../utils/paymentCardNumberUtil';
import { formatCvv, isValidCardCvv } from '../../utils/paymentCvvUtil';
import {
  DISABLED_PAYMENT_PLAN_CARRIERS,
  PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX,
  PAYMENT_WIDGET_FORM_CONFIG,
} from '../paymentWidget/constants';
import { isCFCreditCardPaymentMethodVisible } from '../paymentWidget/utils';
import { getOwnersOfficerMessages } from '../quoteOwnersOfficersDetails/utils';
import { useQuotesStore } from '../quotes/store';
import { getSumTotalConstructionProjectValue } from '../quotes/utils';
import { PAYMENT_ERROR_MESSAGE, PAYMENT_STORE_KEYS, POLICY_FALLBACK_FAILURE_REASON } from './constants';
import { usePaymentStore } from './store';

export const getLiabilityLimitValueByPolicyType = (quote?: IQuoteInfo) => {
  const { liabilityLimitKeys } = getLiabilityLimitsByPolicy();
  let liabilityLimitContent = '';

  liabilityLimitKeys.map((item: any, index: number) => {
    if (quote?.liabilityLimits) {
      liabilityLimitContent = `${liabilityLimitContent} $ ${removeDecimal(
        formatPrice(quote?.liabilityLimits?.[item as keyof ILiabilityLimitsInfo]),
      )} ${index < liabilityLimitKeys.length - 1 ? '/' : ''}`;
    }
  });

  return liabilityLimitContent;
};

export const generateOwnerOfficersMessages = (applicationData: IApplicationInfo) =>
  getOwnersOfficerMessages({
    states: applicationData?.locationDetails?.map((location: ILocationInfo) => location?.address?.state) || [],
    legalEntity: applicationData?.companyStructure?.legalEntityType || '',
    messageType: OwnerOfficerMessageType.BIND,
  });

export const isPaymentDetailsValidUtil = ({ form }: { form: IFormInstance }) => {
  if (
    form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name) &&
    isValidCardNumber(denormalizeCardNumber(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardNumber.name) || '')) &&
    isValidCardExpiryDate(
      normalizeCardExpiryDate(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardExpiry.name) || ''),
    ) &&
    isValidCardCvv(formatCvv(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardCvv.name) || '')) &&
    form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardName.name)?.length >= 2
  ) {
    return true;
  }
  return false;
};

export const getSelectedCarrierDetails = ({
  selectedCarriersInfo,
  selectedQuote,
}: {
  selectedCarriersInfo?: ICarrierInfo[];
  selectedQuote?: IQuoteInfo;
}) => selectedCarriersInfo?.find((carrier) => carrier.carrierId === selectedQuote?.carrierId);

export const getPaymentSelectedQuote = (quotesData: IQuoteInfo[]): IQuoteInfo | undefined => {
  const { applicationData } = useApplicationStore.getState();

  switch (applicationData?.applicationStatus?.statusCode as ApplicationStatus) {
    case ApplicationStatus.BIND_IN_PROGRESS:
      return quotesData?.find(
        (quote) => quote.quoteStatus && [QuoteStatus.BOUND].includes(quote.quoteStatus.statusCode),
      );

    case ApplicationStatus.BIND_PENDING:
      return quotesData?.find((quote) =>
        [QuotePaymentStatus.SUCCESS, QuotePaymentStatus.TOKENIZED].includes(quote?.quotePaymentStatus!),
      );
    case ApplicationStatus.PAYMENT_IN_PROGRESS:
      return quotesData?.find((quote) => quote?.quotePaymentStatus === QuotePaymentStatus.IN_PROGRESS);
    case ApplicationStatus.BOUND:
      return quotesData?.find(
        (quote) => quote.quoteStatus && [QuoteStatus.BOUND].includes(quote.quoteStatus.statusCode),
      );
    default:
      return undefined;
  }
};

export const getEffectiveAmount = ({
  storePaymentPlans,
  selectedQuote,
  form,
}: {
  storePaymentPlans: IPaymentPlan[];
  selectedQuote?: IQuoteInfo;
  form?: IFormInstance;
}) => {
  if (storePaymentPlans?.length > 0 && form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name)) {
    return formatPrice(
      storePaymentPlans
        ?.find((plan) => {
          return plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name);
        })
        ?.downPaymentDetails?.downPaymentAmount?.toString(),
    );
  }
  return formatPrice(selectedQuote?.totalAmount?.toString());
};

export const getPaymentSummaryInfo = ({
  selectedQuote,
  accountData,
}: {
  selectedQuote?: IQuoteInfo;
  accountData?: IAccount;
}): IAntdOptions[] => {
  return [
    {
      label: 'Policy Type',
      value: (selectedQuote?.policyType && POLICY_NAME_MAPPING[selectedQuote?.policyType]) || '',
    },
    { label: 'Account Name', value: accountData?.accountName || '' },
    {
      label: 'Total Amount',
      value: (selectedQuote?.totalAmount && `$${formatPrice(selectedQuote?.totalAmount.toString()) || ''}`) || '',
    },
    { label: 'Quote ID', value: `${selectedQuote?.carrierQuoteId ?? ''}` },
    { label: 'Effective Date', value: formatDate(selectedQuote?.insuranceEffectiveDate, 'MM-DD-YYYY', false) || '' },
    { label: 'Expiration Date', value: formatDate(selectedQuote?.insuranceExpiryDate, 'MM-DD-YYYY', false) || '' },
    { label: 'Liability Limits', value: getLiabilityLimitValueByPolicyType(selectedQuote) || '' },
    {
      label: 'Project Value',
      value: getSumTotalConstructionProjectValue()
        ? normalizeMoneyInput(getSumTotalConstructionProjectValue()?.toString())
        : '',
    },
  ];
};

const getCreditCardDetailsUtil = (form?: IFormInstance): ICardPaymentInstrumentDetails => {
  return {
    cardNumber: denormalizeCardNumber(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardNumber.name) || ''),
    expiryMonth: parseInt(
      denormalizeCardExpiryDate(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardExpiry.name) || '')?.month,
    ),
    expiryYear: parseInt(
      denormalizeCardExpiryDate(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardExpiry.name) || '')?.year,
    ),
    nameOnCard: form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardName.name) || '',
    cardType: CardType.CREDIT,
    cvv: formatCvv(form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.cardCvv.name) || ''),
  };
};

const setPaymentPlanUtil = async (
  selectedPaymentPlan: IPaymentPlan,
  selectedPaymentAdditionalDetails?: IPaymentPlanSelectedAdditionalDetail[],
): Promise<boolean> => {
  const { applicationData } = useApplicationStore.getState();
  const { setPaymentPlan, updatePaymentsByKey } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  try {
    const response = await setPaymentPlan({
      applicationId: applicationData?.applicationId!,
      cfPaymentPlanId: selectedPaymentPlan?.cfPaymentPlanId,
      cfQuoteId: selectedQuote?.cfQuoteId!,
      paymentPlanSelectedAdditionalDetails: selectedPaymentAdditionalDetails,
    });
    updatePaymentsByKey(PAYMENT_STORE_KEYS.SET_PAYMENT_PLAN_API_DATA, response);

    return true;
  } catch (error: any) {
    errorV2(error?.[0]?.errorMessage || PAYMENT_ERROR_MESSAGE.SET_PAYMENT_PLAN);
    return false;
  }
};

const getPaymentOptionsUtil = async () => {
  const { getPaymentOptions, updatePaymentsByKey } = usePaymentStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  try {
    const response = await getPaymentOptions({
      applicationId: applicationData?.applicationId!,
      cfQuoteId: selectedQuote?.cfQuoteId!,
    });

    updatePaymentsByKey(PAYMENT_STORE_KEYS.PAYMENT_OPTIONS_API_DATA, response);
    return true;
  } catch (error: any) {
    errorV2(error?.[0]?.errorMessage || PAYMENT_ERROR_MESSAGE.GET_PAYMENT_OPTIONS);
    return false;
  }
};

const getMakePaymentApiPayloadUtil = (form?: IFormInstance) => {
  const { paymentOptionsData } = usePaymentStore.getState();

  return isCFCreditCardPaymentMethodVisible()
    ? {
        paymentInstrument: {
          paymentInstrumentType: paymentOptionsData?.paymentOptions?.[0]?.paymentInstrumentType!,
          paymentInstrumentDetails: {
            ...getCreditCardDetailsUtil(form),
          },
        },
      }
    : undefined;
};

const makePaymentUtil = async (form?: IFormInstance) => {
  const { selectedUserProfile } = useProfileV2Store.getState();
  const { makePayment, updatePaymentsByKey, setIsBounding } = usePaymentStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const currentURL = new URL(`${window.location.origin}/public/payment/return-url`);

  // If profileId query param exists it will update the value, else it will add the query param to the URL
  currentURL.searchParams.set(QUERY_PARAMS.PROFILE_ID, selectedUserProfile?.agentProfileId || '');

  try {
    //TODO: Generate this Api data based on conditions, will be changed in future with more carrier payment integration.
    const response = await makePayment({
      applicationId: applicationData?.applicationId!,
      cfQuoteId: selectedQuote?.cfQuoteId!,
      returnURI: currentURL?.toString(),
      ...getMakePaymentApiPayloadUtil(form),
    });
    updatePaymentsByKey(PAYMENT_STORE_KEYS.MAKE_PAYMENT_API_DATA, response);
    return true;
  } catch (error: any) {
    errorV2(error?.[0]?.errorMessage || PAYMENT_ERROR_MESSAGE.MAKE_PAYMENT);
    setIsBounding(false);
    return false;
  }
};

export const bindAsyncPolicyUtil = async (): Promise<boolean> => {
  const { setIsBounding, bindPolicyV2, fetchPolicy, pollApplication } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { applicationData, updateApplicationByKey } = useApplicationStore.getState();
  const { setCfModalData } = useCFModalStore.getState();

  setIsBounding(true);
  try {
    // If it is happy flow and we have selected quote
    if (selectedQuote) {
      let cfPolicyId = selectedQuote?.cfPolicyId;
      // both payment option DEFERRED, NON_DEFERRED bind logic resides here

      // either status is QUOTED/REFERRED/BIND_PENDING then for binding start we need to call bindPolicy function
      if (
        [ApplicationStatus.QUOTED, ApplicationStatus.REFERRED, ApplicationStatus.BIND_PENDING].includes(
          applicationData?.applicationStatus?.statusCode!,
        )
      ) {
        // Make bind quote api call and get cfPolicyId for polling
        const data = await bindPolicyV2({
          applicationId: applicationData?.applicationId!,
          cfQuoteId: selectedQuote?.cfQuoteId!,
        });

        cfPolicyId = data.cfPolicyId;
      }

      // if we dont get policy id then we dont want to start polling. (This will be never happen but its just safe check)
      if (!cfPolicyId) {
        return false;
      }

      // Now we have policy id so we can start poling
      const response = await pollApplication(applicationData?.applicationId!);
      if (!response) {
        return false;
      }

      // After  poll complete
      switch (response.applicationStatus?.statusCode) {
        // Status QUOTED/REFERRED or payment that means policy bound failed, so we need to display the failing reasons.
        case ApplicationStatus.QUOTED:
        case ApplicationStatus.REFERRED:
        case ApplicationStatus.BIND_PENDING:
          // It means Policy binding fail, in this case we need to display failing reasons so we call fetch policy
          await fetchPolicy({
            cfPolicyId,
            cfQuoteId: selectedQuote?.cfQuoteId || '',
            applicationId: selectedQuote?.applicationId || '',
          });
          setCfModalData({ isVisible: true, popupType: POPUP_TYPE.POLICY_DECLINED_REASONS });
          return false;
        case ApplicationStatus.BOUND:
          updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.CONFIRMATION);
          // In case of success
          return true;
        default:
          return false;
      }
    } else {
      // if page refresh and we dont have selected quote
      if (applicationData?.applicationStatus?.statusCode === ApplicationStatus.BIND_IN_PROGRESS) {
        // Now we have policy id so we can start poling
        const response = await pollApplication(applicationData?.applicationId!);
        if (!response) {
          return false;
        }
        switch (response.applicationStatus?.statusCode) {
          case ApplicationStatus.QUOTED:
          case ApplicationStatus.REFERRED:
            errorV2(POLICY_FALLBACK_FAILURE_REASON, 3);
            updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.QUOTES);
            return false;
          case ApplicationStatus.BOUND:
            updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.CONFIRMATION);
            return true;

          default:
            return false;
        }
      }
      return false;
    }
  } catch (error: any) {
    errorV2(generateAPIErrorMessage(error, PAYMENT_ERROR_MESSAGE.BIND_POLICY));
    return false;
  } finally {
    setIsBounding(false);
  }
};

export const handleBindPolicy = async (form?: IFormInstance) => {
  const { selectedQuote } = useQuotesStore.getState();
  const { updateApplicationByKey } = useApplicationStore.getState();
  const {
    paymentPlans: storePaymentPlans,
    setIsBounding,
    paymentOptionsData,
    updatePaymentsByKey,
  } = usePaymentStore.getState();

  let isValidToMoveAhead = true;
  if (selectedQuote?.cfQuoteId) {
    const selectedPaymentPlan = storePaymentPlans?.find(
      (plan) => plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name),
    );

    if (selectedPaymentPlan || DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '')) {
      setIsBounding(true);

      if (selectedPaymentPlan && paymentOptionsData?.isPaymentSupported) {
        // Start: For carriers which give static payment link, we directly redirect to that link. Example (First Insurance Carrier)
        if (
          paymentOptionsData?.paymentOptions?.[0]?.paymentIntegrationMethods?.[0] ===
          PaymentIntegrationMethod.STATIC_PAYMENT_LINK
        ) {
          window.open(`${paymentOptionsData?.paymentOptions?.[0]?.staticPaymentURL}`, '_blank', 'noopener=true');
          setIsBounding(false);
          return;
        }
        // End

        isValidToMoveAhead = await makePaymentUtil(form);
        const { makePaymentData } = usePaymentStore.getState();

        if (!isValidToMoveAhead) {
          return;
        }

        if (makePaymentData) {
          if (
            [QuotePaymentStatus.SUCCESS, QuotePaymentStatus.TOKENIZED].includes(makePaymentData?.quotePaymentStatus!)
          ) {
            isValidToMoveAhead = await bindAsyncPolicyUtil();

            if (!isValidToMoveAhead) {
              return;
            }

            updateApplicationByKey(APPLICATION_STORE_KEYS.CURRENT_STEP, APPLICATION_TABS.CONFIRMATION);
          } else if ([QuotePaymentStatus.IN_PROGRESS].includes(makePaymentData?.quotePaymentStatus!)) {
            if (
              [PaymentIntegrationMethod.URL_REDIRECT]?.includes(
                makePaymentData?.paymentIntegrationDetails?.paymentIntegrationMethod,
              )
            ) {
              const paymentRedirectURL = makePaymentData?.paymentIntegrationDetails?.transactionGatewayURI;
              if (paymentRedirectURL) {
                window.location.href = paymentRedirectURL;
              }
            }

            if (
              [PaymentIntegrationMethod.IFRAME]?.includes(
                makePaymentData?.paymentIntegrationDetails?.paymentIntegrationMethod,
              )
            ) {
              const paymentIframeURL = makePaymentData?.paymentIntegrationDetails?.transactionGatewayURI;
              if (paymentIframeURL) {
                updatePaymentsByKey(PAYMENT_STORE_KEYS.PAYMENT_IFRAME_URL, paymentIframeURL);
              }
            }
          } else if ([QuotePaymentStatus.FAILED].includes(makePaymentData?.quotePaymentStatus!)) {
            errorV2(makePaymentData?.failures?.[0]?.reason || PAYMENT_ERROR_MESSAGE.MAKE_PAYMENT, 5);
            setIsBounding(false);
          }
        }
      } else if (selectedPaymentPlan && !paymentOptionsData) {
        await bindAsyncPolicyUtil();
      }
    }
  }
};

export const handleConfirmSelectPlan = async (form: IFormInstance) => {
  const { setCfModalData } = useCFModalStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  const { paymentPlans: storePaymentPlans, updatePaymentsByKey } = usePaymentStore.getState();

  if (selectedQuote?.cfQuoteId) {
    const selectedPaymentPlan = storePaymentPlans?.find(
      (plan) => plan?.cfPaymentPlanId === form?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name),
    );

    if (DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '')) {
      if (selectedQuote?.carrierId === Carrier.COWBELL) {
        setCfModalData({ isVisible: true, popupType: POPUP_TYPE.PAYMENT_UW_STATEMENT_MODAL });
      } else {
        await bindAsyncPolicyUtil();
      }
    }

    if (selectedPaymentPlan) {
      const isPaymentAdditionalDetailsRequired = isEmpty(selectedPaymentPlan.additionalDetails) ? false : true;
      const selectedPaymentAdditionalDetails = selectedPaymentPlan.additionalDetails?.map((additionalDetail) => {
        const additionalDetailAnswer = form?.getFieldValue(
          `${PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX}_${additionalDetail?.additionalDetailCategory}`,
        );
        return {
          additionalDetailCategory: additionalDetail?.additionalDetailCategory,
          additionalDetailAnswer: additionalDetailAnswer?.toString(),
        };
      });
      const isAllPaymentAdditionalDetailsSelected = selectedPaymentAdditionalDetails?.every(
        (item) => item?.additionalDetailAnswer,
      );
      if (
        !isPaymentAdditionalDetailsRequired ||
        (isPaymentAdditionalDetailsRequired && isAllPaymentAdditionalDetailsSelected)
      ) {
        updatePaymentsByKey(PAYMENT_STORE_KEYS.IS_PAYMENT_PLAN_CONFIRMED_LOADING, true);
        await setPaymentPlanUtil(selectedPaymentPlan, selectedPaymentAdditionalDetails);

        if (selectedPaymentPlan?.paymentPlanType === PaymentPlanType.NON_DEFERRED) {
          await getPaymentOptionsUtil();
        }

        updatePaymentsByKey(PAYMENT_STORE_KEYS.IS_PAYMENT_PLAN_CONFIRMED_LOADING, false);

        const { setPaymentPlanData } = usePaymentStore.getState();

        if (setPaymentPlanData?.selectedPaymentPlanId) {
          updatePaymentsByKey(PAYMENT_STORE_KEYS.IS_PAYMENT_PLAN_CONFIRMED, true);
        }
      }
    }
  }
};

export const autoFillSelectedPaymentAdditionalDetails = async () => {
  const { paymentForm, paymentPlans } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  const selectedPaymentPlan = paymentPlans?.find(
    (paymentPlan) => paymentPlan?.cfPaymentPlanId === selectedQuote?.selectedCFPaymentPlanID,
  );

  selectedPaymentPlan?.additionalDetails?.forEach((paymentPlan) => {
    paymentForm?.setFieldValue(
      `${PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX}_${paymentPlan?.additionalDetailCategory}`,
      paymentPlan?.answer,
    );
  });
};
