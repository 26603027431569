import { MailOutlined, PhoneOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
import { IAgencyNetworkUserInfo, IAgentInfo, IUpdateAgentInput } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserRole, AgentPortalUserType, AgentProfileStatus } from '@coverforce-platform/cf-common-types';

import { USER_ROLE_DISPLAY_NAME } from '../../globalConstants';
import { deNormalizePhoneNumber, getEmailRegex, getPhoneNumberRegex, normalizePhoneNumber } from '../../helpers/Utils';
import { IEditProfile } from '../../pages/v2/profile/interface';
import { IFormConfig } from '../../ui-core/V2/cfFormDrawer/interface';

export const getName = (member: IAgentInfo | IAgencyNetworkUserInfo) => `${member.firstName} ${member.lastName}`;

export const getUpdatedUserPayload = (user: IEditProfile | IAgencyNetworkUserInfo): IUpdateAgentInput => {
  const { agentId, firstName, lastName, email, phone } = user;

  const payload: IUpdateAgentInput = {
    agentId,
    firstName,
    lastName,
    email,
    phone: deNormalizePhoneNumber(phone),
  };
  return payload;
};

export const getDisplayNameByRole = (role: AgentPortalUserRole): string => {
  switch (role) {
    case AgentPortalUserRole.AGENT:
      return USER_ROLE_DISPLAY_NAME.AGENT;

    case AgentPortalUserRole.AGENCY_ADMIN:
      return USER_ROLE_DISPLAY_NAME.AGENCY_ADMIN;

    case AgentPortalUserRole.AGENCY_NETWORK_USER:
      return USER_ROLE_DISPLAY_NAME.AGENCY_NETWORK_USER;

    default:
      return '';
  }
};

export const getRoleOptions = (userType?: AgentPortalUserType) =>
  userType === AgentPortalUserType.AGENCY_NETWORK
    ? [
        // TODO: check these values
        {
          value: AgentPortalUserRole.AGENCY_NETWORK_USER,
          label: getDisplayNameByRole(AgentPortalUserRole.AGENCY_NETWORK_USER),
        },
      ]
    : [{ value: AgentPortalUserRole.AGENT, label: getDisplayNameByRole(AgentPortalUserRole.AGENT) }];

export const getTeamMemberFormConfig = (mode: string, userType?: AgentPortalUserType): IFormConfig[] => [
  {
    dataIndex: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
    disabled: mode === 'edit',
    prefix: <UserOutlined />,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter first name.'));
        },
      },
    ],
  },
  {
    dataIndex: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    disabled: mode === 'edit',
    prefix: <UserOutlined />,
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (value?.trim()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter last name.'));
        },
      },
    ],
  },
  {
    dataIndex: 'email',
    label: 'Email',
    placeholder: 'email@email.com',
    type: 'email',
    prefix: <MailOutlined />,
    disabled: mode === 'edit',
    rules: [
      {
        required: true,
        pattern: getEmailRegex(),
        message: 'Please enter valid email address.',
      },
    ],
  },
  {
    dataIndex: 'phone',
    label: 'Phone',
    placeholder: '(234) 567-8910',
    maxLength: 14,
    type: 'tel',
    prefix: <PhoneOutlined />,
    rules: [
      {
        required: true,
        validator: (_: any, value: any) => {
          value = normalizePhoneNumber(value?.trim());
          if (value && value.toString().length >= 14 && getPhoneNumberRegex(value.toString())) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please enter valid 10 digit phone number.'));
        },
      },
    ],
  },
  {
    dataIndex: 'role',
    label: 'Role',
    placeholder: userType === AgentPortalUserType.AGENCY_NETWORK ? 'Network User' : 'Agent',
    prefix: <StarOutlined />,
    type: 'select',
    disabled: mode === 'edit',
    options: getRoleOptions(userType),
    rules: [{ required: true, message: 'Please select role.' }],
    // tooltip: 'An Admin holds the right to close the entire CoverForce Account',
  },
];

export const getInitialAddUserData = (role: AgentPortalUserRole) => {
  return {
    agentId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    authProviderId: '',
    agencyNetworkName: '',
    role,
  };
};

export const getTeamManagementSubtitleText = (theme: any) => {
  if (!theme?.config?.disabledAddUser) {
    return 'Invite members of your team to quote with CoverForce! They’ll receive an email from us inviting them to set up an account with your agency.';
  } else {
    return '';
  }
};

export const getAgentStatusConfirmationModalText = (status?: AgentProfileStatus) => {
  switch (status) {
    case AgentProfileStatus.ACTIVE:
      return 'Are you sure you want to activate this Agent?';
    case AgentProfileStatus.DEACTIVATED:
      return 'Are you sure you want to deactivate this Agent?';
    case AgentProfileStatus.REJECTED:
      return 'Are you sure you want to reject this Agent?';
    case AgentProfileStatus.DELETED:
      return 'Are you sure you want to delete this Agent?';
    default:
      return 'Are you sure you want to perform this action?';
  }
};
