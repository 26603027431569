import { useEffect, useState } from 'react';

import CFLoader from '../../ui-core/V2/cfLoader/cfLoader';
import { BIBERK_APPOINTMENT } from './constants';
import { IBiBerkAppointmentDrawerProps } from './interface';
import { BiBerkAppointmentDrawerStyled, BiBerkAppointmentIframeStyled } from './styles';

const BiBerkAppointmentDrawer = ({ width = 700 }: IBiBerkAppointmentDrawerProps) => {
  const [isBiBerkFormLoading, setIsBiBerkFormLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsBiBerkFormLoading(false);
    }, 1000);
  }, []);

  return (
    <BiBerkAppointmentDrawerStyled
      id='drawer_biberk_appointment'
      width={width > window.innerWidth ? window.innerWidth : width}
      title={BIBERK_APPOINTMENT.TITLE}
    >
      {isBiBerkFormLoading && <CFLoader marginTop='50%' marginBottom='50%' />}
      <BiBerkAppointmentIframeStyled title={BIBERK_APPOINTMENT.TITLE} src={BIBERK_APPOINTMENT.GOOGLE_FORM_URL} />
    </BiBerkAppointmentDrawerStyled>
  );
};
export default BiBerkAppointmentDrawer;
