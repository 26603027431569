import { Carrier } from '@coverforce-platform/cf-common-types';

export const PAYMENT_WIDGET_FORM_CONFIG = {
  paymentPlan: {
    id: 'form_item_sel_payment_type',
    key: 'payment_plan',
    name: 'payment_plan',
  },
  cardNumber: {
    id: 'form_item_inp_card_number',
    key: 'card_number',
    name: 'card_number',
  },
  cardExpiry: {
    id: 'form_item_inp_card_expiry',
    key: 'card_expiry',
    name: 'card_expiry',
  },
  cardCvv: {
    id: 'form_item_inp_card_cvv',
    key: 'card_cvv',
    name: 'card_cvv',
  },
  cardName: {
    id: 'form_item_inp_card_name',
    key: 'card_name',
    name: 'card_name',
  },
};

export const PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX = 'payment_additional_details';

export const DISABLED_PAYMENT_PLAN_CARRIERS: string[] = [Carrier.COALITION, Carrier.COALITION_SURPLUS, Carrier.COWBELL];

export const PAYMENT_CTA_TEXT = {
  CONFIRM_PAYMENT_PLAN: 'Confirm Payment Plan',
  BIND_POLICY: 'Bind Policy',
};
