import { DATE_FORMAT } from '../../globalConstants';
import { formatDate, formatPrice } from '../../helpers/Utils';
import {
  PFQuoteDetailAccordianNameStyled,
  PFQuoteDetailAccordianRowStyled,
  PFQuoteDetailAccordianValueStyled,
  PFQuoteDetailAccordionRowDividerStyled,
  PFQuoteDetailAccordionStyled,
} from '../pfQuoteDetails/styles';
import { usePFQuotesStore } from '../pfQuotes/store';
import { IPFQuoteInstallmentDetailsProps } from './interface';

const PFQuoteInstallmentDetails = (pfQuoteInstallmentDetailsProps: IPFQuoteInstallmentDetailsProps) => {
  const { selectedPFQuote } = usePFQuotesStore();

  const { installmentAmount = 0, firstDueDate, numberOfInstallments = 0 } = selectedPFQuote?.installmentDetails || {};

  return (
    <PFQuoteDetailAccordionStyled
      id='pf_quote_premium_financed_installment_details'
      {...pfQuoteInstallmentDetailsProps}
    >
      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Installment Amount</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          ${formatPrice(installmentAmount?.toString())}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>First Due Date</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>
          {formatDate(firstDueDate, DATE_FORMAT.DDMMMYYYY, false)}
        </PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />

      <PFQuoteDetailAccordianRowStyled>
        <PFQuoteDetailAccordianNameStyled>Number Of Installments</PFQuoteDetailAccordianNameStyled>
        <PFQuoteDetailAccordianValueStyled>{numberOfInstallments}</PFQuoteDetailAccordianValueStyled>
      </PFQuoteDetailAccordianRowStyled>
      <PFQuoteDetailAccordionRowDividerStyled />
    </PFQuoteDetailAccordionStyled>
  );
};

export default PFQuoteInstallmentDetails;
