import { logError } from './sentry';

export const saveToSessionStorage = (storageKey: string, storageData: any): void => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn('Window is not available in saveToSessionStorage');
  }

  try {
    sessionStorage.setItem(storageKey, JSON.stringify(storageData));
  } catch (err) {
    logError({
      error: `Error in saveToSessionStorage for key: ${storageKey}, value: ${JSON.stringify(
        storageData,
      )}, and error: ${JSON.stringify(err)}`,
    });
  }
};
