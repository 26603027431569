import { useEffect } from 'react';

import { errorV2 } from '../../../ui-core/Notification';
import CFLoader from '../../../ui-core/V2/cfLoader/cfLoader';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { bindAsyncPolicyUtil } from '../../payment/utils';
import { getBindUWStatementData } from './componentUtils';
import { BIND_UW_STATEMENT_MODAL_MESSAGES } from './constants';
import { useBindUWStatementStore } from './store';

const BindUWStatement = () => {
  const { bindUWStatementLoading, bindUWStatementData, fetchBindUWStatements, clearBindUWStatementStore } =
    useBindUWStatementStore();
  const { clearCfModal } = useCFModalStore();

  useEffect(() => {
    (async () => {
      try {
        await fetchBindUWStatements({ bindUWStatementLoading: true });
      } catch (error) {
        errorV2(BIND_UW_STATEMENT_MODAL_MESSAGES.FETCH_BIND_UW_STATEMENTS_ERROR);
        clearCfModal();
      }
    })();

    return () => {
      clearBindUWStatementStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBindUWStatementAccept = async () => {
    bindAsyncPolicyUtil();
    clearCfModal();
  };

  return (
    <CFModal
      width={800}
      title='Disclaimers'
      okText='Accept & Bind'
      cancelText='Cancel'
      onOk={handleBindUWStatementAccept}
      okButtonProps={{ id: 'btn_ok_bind_uw_statement', disabled: bindUWStatementLoading }}
      cancelButtonProps={{ id: 'btn_cancel_bind_uw_statement', disabled: bindUWStatementLoading }}
    >
      {bindUWStatementLoading && <CFLoader />}
      {!bindUWStatementLoading && bindUWStatementData && <>{getBindUWStatementData()}</>}
    </CFModal>
  );
};

export default BindUWStatement;
