import { QuoteProposalFormat } from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { APPLICATION_STORE_KEYS } from '../../constants/applicationV2Constants';
import { getAllCarriersInfo } from '../../externalServices/V2/carriersV2';
import { getIsuClpaDetails, getLiabilityLimits, getQuoteProposal } from '../../externalServices/V2/quotes';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { error, loading, success } from '../../ui-core/Notification';
import { downloadFileFromBase64 } from '../../utils/downloadFileFromBase64';
import { useConfigurationStore } from '../configuration/store';
import { QUOTE_PROPOSAL, QUOTES_STORE } from './constants';
import { IQuotesStore } from './interface';
import { checkAndHandleMissingCarrierQuotes, getFetchQuotesDetails } from './utils';

export const useQuotesStore = create<IQuotesStore>()(
  devtools(
    (set, get) => ({
      isQuotesLoading: false,
      quotesData: [],
      quotesError: undefined,
      isuCarriersInfo: [],
      liabilityLimits: [],
      selectedQuote: undefined,
      regenerateQuoteCarrierId: undefined,
      loadingQuoteProposalCarrierIds: [],

      fetchQuotes: async (isQuotesLoading = true) => {
        try {
          set({ isQuotesLoading }, false, QUOTES_STORE.QUOTES_LOADING);
          const { regenerateQuoteCarrierId, fetchQuotes, clearQuotes } = get();
          const { applicationData, policyType, updateApplicationByKey } = useApplicationStore.getState();
          const { applicationStatus } = applicationData || {};
          const { statusCode } = applicationStatus || {};

          const { fetchQuotesFunction, fetchQuotesParams, fetchQuotesPayload } = getFetchQuotesDetails({
            applicationData,
            policyType,
            regenerateQuoteCarrierId,
          });

          const quotesResponse = await fetchQuotesFunction?.(fetchQuotesParams, fetchQuotesPayload);

          // In case user creates new application while on application page with existing application in QUOTING
          // we need to not let it update any data and also clear the quotes so as to not interfere with newly created application
          if (!window.location.pathname.includes(fetchQuotesParams?.applicationId)) {
            clearQuotes();
            return;
          }

          // If after fetching the quotes, the status was CREATED, IN_PROGRESS
          // we change it to QUOTING and poll for quotes by calling this function again
          if (
            [ApplicationStatus.CREATED, ApplicationStatus.IN_PROGRESS].includes(statusCode!) ||
            (regenerateQuoteCarrierId && statusCode === ApplicationStatus.QUOTED)
          ) {
            applicationData!.applicationStatus!.statusCode = ApplicationStatus.QUOTING;
            updateApplicationByKey(APPLICATION_STORE_KEYS.APPLICATION_DATA, applicationData);
            return await fetchQuotes();
          } else {
            // If after fetching the quotes, the status was QUOTING,
            // we change it to QUOTED and set the received data in quotesData
            if (statusCode === ApplicationStatus.QUOTING) {
              applicationData!.applicationStatus!.statusCode = ApplicationStatus.QUOTED;
              updateApplicationByKey(APPLICATION_STORE_KEYS.APPLICATION_DATA, applicationData);
            }

            const finalQuotesData = checkAndHandleMissingCarrierQuotes(quotesResponse.quotes, true);

            set(
              {
                quotesData: finalQuotesData,
                quotesError: undefined,
                regenerateQuoteCarrierId: undefined,
              },
              false,
              QUOTES_STORE.QUOTES_SUCCESS,
            );
            return finalQuotesData;
          }
        } catch (err) {
          const { quotesData: existingQuotesData } = get();
          const finalQuotesData = checkAndHandleMissingCarrierQuotes(existingQuotesData);
          set(
            {
              quotesData: finalQuotesData,
              quotesError: err,
              regenerateQuoteCarrierId: undefined,
            },
            false,
            QUOTES_STORE.QUOTES_ERROR,
          );
          return finalQuotesData;
        } finally {
          set({ isQuotesLoading: false }, false, QUOTES_STORE.QUOTES_LOADING);
        }
      },

      updateQuotesByKey: async (storeKey: keyof IQuotesStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, QUOTES_STORE.UPDATE_QUOTES_STORE_BY_KEY),

      updateSelectedCarrierInfo: async () => {
        try {
          const { selectedCarriersInfo, updateApplicationByKey } = useApplicationStore.getState();
          const carrierInfoResponse = await getAllCarriersInfo();
          const { carriers } = carrierInfoResponse;
          selectedCarriersInfo.forEach((item) => {
            const selectedCarrier = carriers.find((carrier) => carrier.carrierId === item.carrierId);
            if (selectedCarrier) {
              item.amBestRating = selectedCarrier?.amBestRating;
              item.marketInfoTags = selectedCarrier?.marketInfoTags;
            }
          });
          updateApplicationByKey(APPLICATION_STORE_KEYS.SELECTED_CARRIERS_INFO, selectedCarriersInfo);
        } catch (err) {
          // Do nothing in this case
        }
      },

      fetchIsuClpaDetails: async () => {
        try {
          const { applicationData } = useApplicationStore.getState();
          const selectedCarriers = getSelectedCarriersFromApplication(applicationData);
          const isuClpaDetailsResponse = await getIsuClpaDetails({ carrierIds: selectedCarriers });
          set({ isuCarriersInfo: isuClpaDetailsResponse.carriers }, false, QUOTES_STORE.ISU_CLPA_DETAILS_SUCCESS);
        } catch (err) {
          set({ isuCarriersInfo: [] }, false, QUOTES_STORE.ISU_CLPA_DETAILS_ERROR);
        }
      },

      fetchLiabilityLimits: async () => {
        try {
          const { applicationData } = useApplicationStore.getState();
          const { applicationId = '' } = applicationData || {};
          const liabilityLimitsResponse = await getLiabilityLimits({ applicationId });
          set(
            { liabilityLimits: liabilityLimitsResponse.liabilityLimits },
            false,
            QUOTES_STORE.LIABILITY_LIMITS_SUCCESS,
          );
        } catch (err) {
          set({ liabilityLimits: [] }, false, QUOTES_STORE.LIABILITY_LIMITS_ERROR);
        }
      },

      fetchQuoteProposal: async (quote) => {
        const { userTheme } = useConfigurationStore.getState();
        const { cfQuoteId = '', carrierQuoteId = '', applicationId = '', carrierId } = quote || {};

        const quoteProposalMessage = loading(QUOTE_PROPOSAL.LOADING, 60, userTheme?.colorScheme?.primary);
        // INFO: here use get().loadingQuoteProposalCarrierIds because i want latest store value not closure one.
        set(
          { loadingQuoteProposalCarrierIds: [...get().loadingQuoteProposalCarrierIds, carrierId!] },
          false,
          QUOTES_STORE.SET_LOADING_STATE_FOR_QUOTE_PROPOSAL,
        );

        try {
          const quoteProposalResponse = await getQuoteProposal({ cfQuoteId, applicationId });
          const { quoteProposalFormat, quoteProposal } = quoteProposalResponse;
          if (quoteProposalFormat === QuoteProposalFormat.BROWSER_URL) {
            window.open(quoteProposal);
          } else {
            downloadFileFromBase64(
              quoteProposalResponse.quoteProposal,
              `quoteProposal-${carrierQuoteId}`,
              quoteProposalResponse?.fileFormat,
            );
          }
          success(QUOTE_PROPOSAL.SUCCESS, 5);
        } catch (err: any) {
          error(err?.[0]?.errorMessage || QUOTE_PROPOSAL.ERROR, 5);
        } finally {
          quoteProposalMessage();
          set(
            {
              loadingQuoteProposalCarrierIds: get().loadingQuoteProposalCarrierIds.filter(
                (carrier) => carrier !== carrierId,
              ),
            },
            false,
            QUOTES_STORE.SET_LOADING_STATE_FOR_QUOTE_PROPOSAL,
          );
        }
      },

      clearQuotes: async () =>
        set(
          {
            isQuotesLoading: false,
            quotesData: [],
            quotesError: undefined,
            isuCarriersInfo: [],
            liabilityLimits: [],
            regenerateQuoteCarrierId: undefined,
            loadingQuoteProposalCarrierIds: [],
          },
          false,
          QUOTES_STORE.CLEAR_QUOTES,
        ),
    }),

    { name: QUOTES_STORE.NAME, anonymousActionType: QUOTES_STORE.ANONYMOUS_ACTION },
  ),
);
