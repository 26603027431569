import { Divider } from 'antd';
import styled from 'styled-components';

import Accordion from '../../ui-core/V2/accordion/accordion';
import CFDrawer from '../../ui-core/V2/cfDrawer/cfDrawer';

export const PFQuoteDetailsDrawerStyled = styled(CFDrawer)`
  .ant-drawer-body {
    padding: 24px;
  }
`;

export const TitleStyled = styled.div`
  color: ${(p) => p?.theme?.colors?.grayscale?.black};
  font-size: ${(p) => p.theme.typography.fontSize.mediumLarge};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
`;

export const PFQuoteDetailAccordionStyled = styled(Accordion)`
  padding-bottom: 20px;

  .ant-collapse-item .ant-collapse-header {
    background: ${(p) => p.theme.colors.primary.bgMuted};
    border-radius: 8px !important;
    padding: 10px;

    .ant-collapse-header-text {
      font-size: ${(p) => p.theme.typography.fontSize.small};
      color: ${(p) => p?.theme?.colorScheme?.primary};
      font-weight: ${(p) => p.theme.typography.fontWeight.medium};
    }
  }
`;

export const PFQuoteDetailAccordianNameStyled = styled.div.attrs((p: any) => p)`
  text-align: left;
  white-space: pre-wrap;
  width: ${(p) => (p.fullWidth ? '100%' : '50%')};

  a {
    color: ${(p) => p?.theme?.colorScheme?.primary};
    &:hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }

    ${(p) =>
      p.disabled
        ? `
  pointer-events: none;
  cursor: default;
  `
        : ''}
  }
`;

export const PFQuoteDetailAccordianRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(p) => p.theme.typography.fontSize.mini};
  align-items: center;
`;

export const PFQuoteDetailAccordianValueStyled = styled.div`
  text-align: right;
  width: 50%;
`;

export const PFQuoteDetailAccordionRowDividerStyled = styled(Divider)`
  margin: 8px 0;
`;
