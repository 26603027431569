import { IGetCarrierRankingInput, IGetCarrierRankingOutput } from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getCarrierRankingByPolicyTypeId } from '../../externalServices/V2/carrierRankingV2';
import { getPFLoginConfig as requestGetPFLoginConfig } from '../../externalServices/V2/pfApplication';
import { IAntdOptions } from '../../globalInterface';
import { usePFApplicationStore } from '../../pages/v2/pfApplication/store';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { errorV2 } from '../../ui-core/Notification';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { useConfigurationStore } from '../configuration/store';
import { PF_APPLICATION_INFO_QUESTIONS_ACTIONS, SUPPORT_CARRIER_FETCH_ERROR } from './constants';
import { IPFApplicationInfoQuestionsStore } from './interface';
import {
  getPFApplicationInfoApiPayload,
  isPFApplicationInfoQuestionsAnswersValid,
  setFormFieldsPFApplicationInfoQuestion,
} from './utils';

const initSupportedCarriersStore = {
  supportedCarriersData: {},
  supportedCarriersLoading: false,
  supportedCarriersError: null,
};

export const usePFApplicationInfoQuestionsStore = create<IPFApplicationInfoQuestionsStore>()(
  devtools(
    (set, get) => ({
      pfApplicationInfoQuestionsElements: undefined,
      pfApplicationInfoQuestionsForm: undefined,
      pfApplicationInfoLoading: false,
      pfLoginConfig: undefined,
      pfApplicationInfoError: undefined,
      supportedCarriersStore: {
        ...initSupportedCarriersStore,
      },

      setPFApplicationInfoQuestionsForm: (formObj: IFormInstance) => {
        set(
          { pfApplicationInfoQuestionsForm: formObj },
          false,
          PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_APP_INFO_FORM,
        );
      },

      validatePFApplicationInfoQuestionsForm: async () => {
        const isValid = await isPFApplicationInfoQuestionsAnswersValid();
        return isValid;
      },

      getPFApplicationInfoQuestionsApiPayload: () => {
        return getPFApplicationInfoApiPayload();
      },

      fetchCarrierRankingByPolicyTypeId: async (params: IGetCarrierRankingInput) => {
        const { allCarriersData } = useConfigurationStore.getState();

        try {
          set(
            {
              supportedCarriersStore: {
                ...initSupportedCarriersStore,
                supportedCarriersLoading: true,
              },
            },
            false,
            PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_SUPPORTED_CARRIERS_LOADING,
          );
          const response: IGetCarrierRankingOutput = await getCarrierRankingByPolicyTypeId({
            agentId: params?.agentId,
            policyTypeId: params?.policyTypeId,
          });

          const supportedCarriersArr: IAntdOptions[] = response?.onCarriers?.map((carrierId) => ({
            value: carrierId,
            label: allCarriersData[carrierId]?.carrierDisplayName,
          }));

          const supportedCarriersData = {
            ...get().supportedCarriersStore.supportedCarriersData,
            [params?.policyTypeId]: supportedCarriersArr,
          };

          set(
            {
              supportedCarriersStore: {
                supportedCarriersData,
                supportedCarriersError: null,
                supportedCarriersLoading: false,
              },
            },
            false,
            PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_SUPPORTED_CARRIERS,
          );
          return supportedCarriersData;
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || SUPPORT_CARRIER_FETCH_ERROR);
          set(
            {
              supportedCarriersStore: {
                supportedCarriersData: {},
                supportedCarriersError: error,
                supportedCarriersLoading: false,
              },
            },
            false,
            PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_SUPPORTED_CARRIERS,
          );

          return undefined;
        }
      },

      getPFLoginConfigs: async () => {
        const { selectedUserProfile } = useProfileV2Store.getState();
        const { userType } = useConfigurationStore.getState();
        try {
          const pfLoginConfig = await requestGetPFLoginConfig({
            agencyOrNetworkId:
              (userType === AgentPortalUserType.AGENCY_NETWORK
                ? selectedUserProfile?.agencyNetworkId
                : selectedUserProfile?.agencyId) || '',
          });
          set({ pfLoginConfig }, false, PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_LOGIN_CONFIGS);
          return pfLoginConfig;
        } catch (error: any) {
          set(
            { pfApplicationInfoError: error },
            false,
            PF_APPLICATION_INFO_QUESTIONS_ACTIONS.SET_PF_LOGIN_CONFIGS_ERROR,
          );
        }
      },

      autoFillPFApplicationInfoQuestionsForm: () => {
        const { pfApplicationInfoQuestionsForm } = get();
        const { pfApplicationData } = usePFApplicationStore.getState();
        if (pfApplicationData) {
          setFormFieldsPFApplicationInfoQuestion();
        } else {
          pfApplicationInfoQuestionsForm?.resetFields();
        }
      },

      updatePFApplicationInfoQuestionByKey: async (storeKey: keyof IPFApplicationInfoQuestionsStore, value: any) =>
        set(
          () => ({ [storeKey]: value }),
          false,
          PF_APPLICATION_INFO_QUESTIONS_ACTIONS.UPDATE_PF_APPLICATION_INFO_BY_KEY,
        ),

      clearPFApplicationInfoQuestions: () => {
        const { pfApplicationInfoQuestionsForm } = get();
        pfApplicationInfoQuestionsForm?.resetFields();
        set(
          {
            pfApplicationInfoQuestionsElements: undefined,
            pfApplicationInfoQuestionsForm: undefined,
            pfApplicationInfoLoading: false,
            pfLoginConfig: undefined,
            pfApplicationInfoError: undefined,
            supportedCarriersStore: {
              ...initSupportedCarriersStore,
            },
          },
          false,
          PF_APPLICATION_INFO_QUESTIONS_ACTIONS.CLEAR_STORE,
        );
      },
    }),
    {
      anonymousActionType: PF_APPLICATION_INFO_QUESTIONS_ACTIONS.ANONYMOUS_ACTION,
      name: PF_APPLICATION_INFO_QUESTIONS_ACTIONS.STORE_NAME,
    },
  ),
);
