import { ICompareQuotesInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { compareQuotes } from '../../../externalServices/V2/quotes';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { downloadPdfFromUrl } from '../../../utils/downloadPdfFromUrl';
import { QUOTE_COMPARISON_MODAL_DETAILS, QUOTE_COMPARISON_STORE_METADATA } from './constants';
import { IQuoteComparisonModalStore } from './interface';

export const useQuoteComparisonModalStore = create<IQuoteComparisonModalStore>()(
  devtools(
    (set, get) => ({
      isQuoteComparisonLoading: false,
      quoteComparisonCarriers: [],

      compareCarrierQuotes: async (isQuoteComparisonLoading = true) => {
        set({ isQuoteComparisonLoading }, false, QUOTE_COMPARISON_STORE_METADATA.QUOTE_COMPARISON_MODAL_LOADING);
        const { quoteComparisonCarriers } = get();
        const { applicationData, policyType } = useApplicationStore.getState();
        const { applicationId } = applicationData || {};

        const quoteComparisonPayload: ICompareQuotesInput = {
          applicationId: applicationId!,
          carrierIds: quoteComparisonCarriers,
          policyType: policyType!,
        };

        const quoteComparisonResponse = await compareQuotes(quoteComparisonPayload);
        await downloadPdfFromUrl({
          downloadUrl: quoteComparisonResponse.presignedUrl,
          fileName: `${QUOTE_COMPARISON_MODAL_DETAILS.DONWLOAD_FILE_NAME}_${applicationId}`,
          forceDownload: true,
        });
      },

      updateQuoteComparisonModalStoreByKey: async (storeKey: keyof IQuoteComparisonModalStore, value: any) =>
        set(
          () => ({ [storeKey]: value }),
          false,
          QUOTE_COMPARISON_STORE_METADATA.UPDATE_QUOTE_COMPARISON_MODAL_STORE_BY_KEY,
        ),

      clearQuoteComparisonStore: () => {
        set(
          { isQuoteComparisonLoading: false, quoteComparisonCarriers: [] },
          false,
          QUOTE_COMPARISON_STORE_METADATA.CLEAR_QUOTE_COMPARISON_MODAL_STORE,
        );
      },
    }),

    {
      anonymousActionType: QUOTE_COMPARISON_STORE_METADATA.ANONYMOUS_ACTION,
      name: QUOTE_COMPARISON_STORE_METADATA.STORE_NAME,
    },
  ),
);
