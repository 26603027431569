/**
 * Converts a nested object into a flat object with dot-separated keys.
 * Accepts an optional level argument to control depth.
 */
type InputObject = Record<string, any>;

type OutputObject = Record<string, any>;
export const flattenedDotSeparatedKeysNestedObject = (
  input: OutputObject,
  level: number = Infinity,
  prefix = '',
): InputObject => {
  const output: InputObject = {};

  const recurse = (obj: any, currentLevel: number, parentKey: string) => {
    for (const key in obj) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null && currentLevel < level) {
        recurse(obj[key], currentLevel + 1, newKey);
      } else {
        output[newKey] = obj[key];
      }
    }
  };

  recurse(input, 0, prefix);
  return output;
};
