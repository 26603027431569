import { DRAWER_TYPE } from '../../globalConstants';
import { useCFDrawerStore } from '../../ui-core/V2/cfDrawer/store';
import { AmtrustAppointment } from '../amtrustAppointmentDrawer/amtrustAppointmentDrawer';
import BiBerkAppointmentDrawer from '../biBerkAppointmentDrawer/biBerkAppointmentDrawer';
import { PFQuoteDetails } from '../pfQuoteDetails/pfQuoteDetails';

const Drawers = () => {
  const { drawerType } = useCFDrawerStore();

  return (
    <>
      {drawerType === DRAWER_TYPE.AMTRUST_APPOINTMENT && <AmtrustAppointment />}
      {drawerType === DRAWER_TYPE.PF_QUOTE_DETAILS && <PFQuoteDetails />}
      {drawerType === DRAWER_TYPE.BIBERK_APPOINTMENT && <BiBerkAppointmentDrawer />}
    </>
  );
};
export default Drawers;
