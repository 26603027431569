import { errorV2 } from '../ui-core/Notification';

interface IDownloadPdfFromUrl {
  downloadUrl: string;
  fileName: string;
  forceDownload?: boolean;
  downloadErrorMessage?: string;
}

export const downloadPdfFromUrl = async ({
  downloadUrl,
  fileName,
  forceDownload = false,
  downloadErrorMessage,
}: IDownloadPdfFromUrl) => {
  if (fileName && downloadUrl) {
    if (forceDownload) {
      await forceDownloadFile({ downloadUrl, fileName, downloadErrorMessage });
    } else {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${fileName}.pdf`;
      link.click();
    }
  }
};

const forceDownloadFile = async ({
  downloadUrl,
  fileName,
  downloadErrorMessage = 'An error occurred in file download!',
}: IDownloadPdfFromUrl) => {
  try {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.pdf`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // Cleanup
  } catch (error) {
    errorV2(downloadErrorMessage);
  }
};
