import { IQuoteComparisonModalStore } from './interface';

export const QUOTE_COMPARISON_MODAL_DETAILS = {
  TITLE: 'Quote Comparison',
  DESCRIPTION: 'Select between 2 to 4 carriers for quotes comparison',
  SUCCESS: 'Quote Comparison downloaded successfully',
  ERROR: 'An error occurred while generating the comparison document',
  DONWLOAD_FILE_NAME: 'Quote_Comparison',
};

export const QUOTE_COMPARISON_STORE_METADATA = {
  QUOTE_COMPARISON_MODAL_LOADING: 'QUOTE_COMPARISON_MODAL_LOADING',
  UPDATE_QUOTE_COMPARISON_MODAL_STORE_BY_KEY: 'UPDATE_QUOTE_COMPARISON_MODAL_STORE_BY_KEY',
  CLEAR_QUOTE_COMPARISON_MODAL_STORE: 'CLEAR_QUOTE_COMPARISON_MODAL_STORE',
  ANONYMOUS_ACTION: 'QUOTE_COMPARISON_STORE_ACTION',
  STORE_NAME: 'Quote Comparison Modal Store',
};

export const QUOTE_COMPARISON_MODAL_STORE_KEYS: { [key: string]: keyof IQuoteComparisonModalStore } = {
  IS_QUOTE_COMPARISON_LOADING: 'isQuoteComparisonLoading',
  QUOTE_COMPARISON_CARRIERS: 'quoteComparisonCarriers',
};
