import { IApplicationInfo, IQuoteInfo } from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus, Carrier, PolicyType, QuoteType } from '@coverforce-platform/cf-common-types';

import { FE_QUOTE_FAILURE_CODES } from '../../constants/quotesConstants';
import { createQuotes, getQuotes, pollForQuotes, regenerateQuote } from '../../externalServices/V2/quotes';
import { IPollForQuotes } from '../../globalInterface';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { useLocationDetailsStore } from '../locationDetails/store';
import { IFetchQuotesDetailsOutput } from './interface';

export const getFetchQuotesDetails = ({
  applicationData,
  policyType,
  regenerateQuoteCarrierId,
}: {
  applicationData?: IApplicationInfo;
  policyType?: PolicyType;
  regenerateQuoteCarrierId?: Carrier;
}): IFetchQuotesDetailsOutput => {
  const { applicationId, applicationStatus } = applicationData || {};
  const { statusCode } = applicationStatus || {};

  const fetchQuotesParams = { applicationId: applicationId! };

  switch (statusCode) {
    case ApplicationStatus.CREATED:
    case ApplicationStatus.IN_PROGRESS: {
      if (regenerateQuoteCarrierId) {
        return {
          fetchQuotesFunction: regenerateQuote,
          fetchQuotesParams: { ...fetchQuotesParams, carrierId: regenerateQuoteCarrierId, policyType },
        };
      } else {
        return { fetchQuotesFunction: createQuotes, fetchQuotesParams };
      }
    }
    case ApplicationStatus.QUOTING: {
      const applicationSelectedCarriers = getSelectedCarriersFromApplication(applicationData);
      const fetchQuotesPayload: IPollForQuotes = {
        totalQuotes: applicationSelectedCarriers.length,
        regenerateQuoteCarrierId,
      };
      return {
        fetchQuotesFunction: pollForQuotes,
        fetchQuotesParams,
        fetchQuotesPayload,
      };
    }
    case ApplicationStatus.QUOTED: {
      if (regenerateQuoteCarrierId) {
        return {
          fetchQuotesFunction: regenerateQuote,
          fetchQuotesParams: { ...fetchQuotesParams, carrierId: regenerateQuoteCarrierId, policyType },
        };
      } else {
        return { fetchQuotesFunction: getQuotes, fetchQuotesParams };
      }
    }
    // Default covers for all other status
    default:
      return { fetchQuotesFunction: getQuotes, fetchQuotesParams };
  }
};

export const checkAndHandleMissingCarrierQuotes = (
  quotesData: IQuoteInfo[],
  showTimeoutFailure = true,
): IQuoteInfo[] => {
  const { selectedCarriersInfo } = useApplicationStore.getState();
  if (quotesData.length < selectedCarriersInfo.length) {
    selectedCarriersInfo.forEach((item) => {
      const selectedCarrierQuote = quotesData.find((quote) => quote.carrierId === item.carrierId);
      if (!selectedCarrierQuote) {
        const failedQuote = {
          quoteType: QuoteType.FAILED,
          carrierId: item.carrierId,
          // cfQuoteId: index,
        } as IQuoteInfo;

        if (showTimeoutFailure) {
          failedQuote.failures = [
            {
              code: FE_QUOTE_FAILURE_CODES.CTO,
              // This is automatically handled in TimeoutReasonsModal component
              reason: '',
            },
          ];
        }
        quotesData.push(failedQuote);
      }
    });
  }
  return quotesData;
};

export const getGaCategoryName = (category: string, carrier = '') => {
  return `${category}For${carrier}`;
};

export const getSumTotalConstructionProjectValue = () => {
  const { locations } = useLocationDetailsStore.getState();
  let totalValue: number = 0;
  locations?.forEach((location) => {
    const value = location?.buildingInformation?.totalConstructionProjectValue ?? 0;
    totalValue += value;
  });
  return totalValue === 0 ? undefined : totalValue;
};
