export const SETTINGS_URL_PREFIX = '/settings/';

export const NETWORK_SETTINGS_URL_PREFIX = '/network-settings/';

export const NAV_ITEMS = {
  HOME: { ID: 'home', TITLE: 'Home', TO_URL: '/' },
  ACCOUNTS: { ID: 'accounts', TITLE: 'Accounts' },
  SETTINGS: { ID: 'settings', TITLE: 'Settings' },
  APPETITE: { ID: 'appetite', TITLE: 'Appetite Guide', TO_URL: '/appetite' },
  NOTIFICATIONS: { ID: 'notifications', TITLE: '' },
  HELP: { ID: 'help', TITLE: 'Help' },
  USER: { ID: 'user', TITLE: 'User' },
  APPLICATIONS: { ID: 'applications', TITLE: 'Applications', TO_URL: '/applications' },
  AGENCY_SETTINGS: { ID: 'agencySettings', TITLE: 'Agency Settings', TO_URL: '/agencies' },
  NETWORK_SETTINGS: { ID: 'networkSettings', TITLE: 'Network Settings' },
  REPORT_CENTER: { ID: 'reportCenter', TITLE: 'Reports Center', TO_URL: '/report-center' },
  TOOLS: { ID: 'tools', TITLE: 'Tools' },
};
