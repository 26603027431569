import { EditOutlined, FileAddOutlined, StopOutlined, StrikethroughOutlined } from '@ant-design/icons';
import { ICreatePFQuoteInput } from '@coverforce-platform/cf-common-api-model';
import { DownPaymentMethod, DownPaymentPaidBy } from '@coverforce-platform/cf-common-types';
import dayjs, { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { cloneDeep } from 'lodash';

import { DATE_FORMAT, FEATURE_FLAG } from '../../globalConstants';
import { IAntdOptions } from '../../globalInterface';
import { deNormalizePhoneNumber, getEmailRegex, getPhoneNumberRegex, normalizePhoneNumber } from '../../helpers/Utils';
import { usePFApplicationStore } from '../../pages/v2/pfApplication/store';
import uwCompanyCarrierMapping from '../../pages/v2/pfApplication/uwcompany-carrier-mapping.json';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import { flattenedDotSeparatedKeysNestedObject } from '../../utils/flattenedDotSeparatedKeysNestedObject';
import { getGrowthbookFeatureValueByKey } from '../../utils/growthbook';
import { parseDotSeparatedKeysNestedObject } from '../../utils/parseDotSeparatedKeysNestedObject';
import { scrollAndFocusFormItemError } from '../../utils/scrollAndFocusFormItemError';
import { sortBy } from '../../utils/sortBy';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { useConfigurationStore } from '../configuration/store';
import { useLocationInputStore } from '../question/locationInputV2/store';
import { validateAddress } from '../question/locationInputV2/utils';
import {
  PF_APPLICATION_INFO_QUESTION_DATA_INDEX,
  PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID,
  PF_APPLICATION_INFO_STORE_KEY,
} from './constants';
import { IPFApplicationInfoQuestions } from './interface';
import { usePFApplicationInfoQuestionsStore } from './store';

extend(isSameOrAfter);

const getInsuredDetailsQuestion = () => {
  return [
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Insured Name',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.NAME,
      placeholder: 'Insured Name',
      tooltip: 'The name of the business being insured',
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value?.trim()) {
              return Promise.reject(new Error('Please enter Insured Name.'));
            }
            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'DBA',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARE_OF,
      placeholder: 'Doing Business As',
      tooltip: "A 'Doing Business As' (DBA) Name is a business' assumed, trade or fictitious name",
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Email',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EMAIL,
      placeholder: 'email@email.com',
      rules: [
        {
          required: true,
          pattern: getEmailRegex(),
          message: 'Please enter a valid Email address.',
        },
      ],
    },

    {
      type: ANTD_QUESTION_TYPE.PHONE,
      name: 'Phone Number',
      placeholder: 'Phone Number',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.PHONE,
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            value = normalizePhoneNumber(value?.trim());

            if (value && value.toString().length >= 14 && getPhoneNumberRegex(value.toString())) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please enter a valid 10 digit Phone Number.'));
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.ADDRESS,
      name: 'Address',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.PRIMARY_ADDRESS,
      rules: [
        {
          required: true,
          message: '',
        },
      ],
    },
  ];
};

const getAllowedPolicies = (): IAntdOptions[] => {
  const { policyTypes } = useConfigurationStore.getState();
  const allowedPoliciesAgency: IAntdOptions[] = [];
  const allowedPolicyTypesGrowthbook = Object.values(
    getGrowthbookFeatureValueByKey(FEATURE_FLAG.ALLOWED_POLICIES) || {},
  );
  Object.keys(policyTypes)?.forEach((policyTypeId: string) => {
    if ((allowedPolicyTypesGrowthbook || []).includes(policyTypeId)) {
      allowedPoliciesAgency.push({ label: policyTypes[policyTypeId], value: policyTypeId });
    }
  });

  return allowedPoliciesAgency;
};

const getUnderWritingCompaniesByCarrier = (carrier: string) => {
  const underWritingCompanies = carrier
    ? uwCompanyCarrierMapping[carrier as keyof typeof uwCompanyCarrierMapping]?.underwritingCompanies || []
    : [];
  return underWritingCompanies.map((underWritingCompany) => ({
    label: underWritingCompany.name,
    value: underWritingCompany.uniqueId,
  }));
};

const getUnderWritingCompaniesOptions = () => {
  const options: IAntdOptions[] = [];

  Object.entries(uwCompanyCarrierMapping).forEach(([, data]) => {
    data.underwritingCompanies.forEach((company) => {
      options.push({
        label: company.name,
        value: company.uniqueId,
      });
    });
  });

  return options;
};

const getPolicyDetailsQuestion = () => {
  const { allCarriersData } = useConfigurationStore.getState();

  return [
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Policy Type',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.POLICY_TYPE,
      placeholder: 'Select Policy Type',
      rules: [{ required: true, message: 'Please select Policy Type.' }],
      options: sortBy({
        arr: getAllowedPolicies(),
        sortKey: 'label',
      }) as unknown as IAntdOptions[],
      filterOption: (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Carrier',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARRIER,
      disabled: true,
      placeholder: 'Select Carrier',
      rules: [{ required: true, message: 'Please select Carrier.' }],
      options: sortBy({
        arr: Object.keys(allCarriersData).map((carrier) => ({
          label: allCarriersData[carrier]?.carrierDisplayName,
          value: carrier,
        })),
        sortKey: 'label',
      }) as unknown as IAntdOptions[],
      filterOption: (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Underwriting Company',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.UW_COMPANY_DETAILS_UNIQUE_ID,
      disabled: true,
      placeholder: 'Select Underwriting Company',
      rules: [{ required: true, message: 'Please select Underwriting Company.' }],
      options: getUnderWritingCompaniesOptions(),
      filterOption: (input: string, option: any) =>
        (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    },
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Premium',
      placeholder: 'Premium',
      decimalAllowed: true,
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.PREMIUM,
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value?.trim() || Number(value) < 0) {
              return Promise.reject('Please enter a value.');
            }

            if (Number(value) > 99999999.99) {
              return Promise.reject('Amount cannot exceed $99,999,999.99');
            }

            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Tax',
      placeholder: 'Tax',
      decimalAllowed: true,
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.TAX,
      rules: [
        {
          validator: (_: any, value: any) => {
            if (!value) {
              return Promise.resolve();
            }

            if (Number(value) > 999999.99) {
              return Promise.reject('Amount cannot exceed $999,999.99');
            }

            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.MONEY,
      name: 'Fee',
      placeholder: 'Fee',
      decimalAllowed: true,
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.FEE,
      rules: [
        {
          validator: (_: any, value: any) => {
            if (!value) {
              return Promise.resolve();
            }

            if (Number(value) > 999999.99) {
              return Promise.reject('Amount cannot exceed $999,999.99');
            }

            return Promise.resolve();
          },
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Policy Number',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.POLICY_NUMBER,
      placeholder: 'Policy Number',
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Invoice Number',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.INVOICE_NUMBER,
      placeholder: 'Invoice Number',
    },
    {
      type: ANTD_QUESTION_TYPE.DATE,
      name: 'Effective Date',
      placeholder: 'Effective Date',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EFFECTIVE_DATE,
      rules: [{ required: true, message: 'Please enter Effective Date.' }],
      format: DATE_FORMAT.MMDDYYYY,
    },
    {
      type: ANTD_QUESTION_TYPE.DATE,
      name: 'Expiration Date',
      placeholder: 'Expiration Date',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EXPIRATION_DATE,
      rules: [{ required: true, message: 'Please enter Expiration Date.' }],
      format: DATE_FORMAT.MMDDYYYY,
    },
  ];
};

const getPremiumFinanceDetailsQuestion = () => {
  return [
    {
      type: ANTD_QUESTION_TYPE.RADIO,
      name: 'Do you want provider to collect down payment during e-sign?',
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.DOWN_PAYMENT_PROCESS,
      options: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      rules: [{ required: true, message: 'Please select either Yes or No.' }],
    },
  ];
};

const getDeclarationStatementsQuestion = () => {
  return [
    {
      type: ANTD_QUESTION_TYPE.RADIO,
      name: `Please confirm the following: `,
      dataIndex: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.STATEMENT_DECLARATION,
      options: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      rules: [
        {
          required: true,
          validator: (_: any, value: any) => {
            if (!value || value === 'false') {
              return Promise.reject(new Error('Please accept to continue.'));
            }
            return Promise.resolve();
          },
        },
      ],
    },
  ];
};

export const getQuestions = (): IPFApplicationInfoQuestions[] => {
  return [
    {
      id: PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.INSURED_DETAILS,
      title: 'Insured Details',
      questions: getInsuredDetailsQuestion(),
      Icon: EditOutlined,
    },
    {
      id: PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.POLICY_DETAILS,
      title: 'Policy Details',
      questions: getPolicyDetailsQuestion(),
      Icon: FileAddOutlined,
    },
    {
      id: PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.PREMIUM_FINANCE_DETAILS,
      title: 'Premium Finance Details',
      questions: getPremiumFinanceDetailsQuestion(),
      Icon: StrikethroughOutlined,
    },
    {
      id: PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.DECLARATION_STATEMENTS,
      title: 'Statements and Declarations',
      questions: getDeclarationStatementsQuestion(),
      Icon: StopOutlined,
    },
  ];
};

export const isPFApplicationInfoQuestionsAnswersValid = async () => {
  const { pfApplicationInfoQuestionsForm: form } = usePFApplicationInfoQuestionsStore.getState();
  const applicationInfoQuestionsAnswer = {
    ...form?.getFieldsValue(),
  };
  const { setShowLocationError } = useLocationInputStore.getState();

  let isPFApplicationInfoAnswersValid = true;
  try {
    if (!validateAddress(applicationInfoQuestionsAnswer?.[PF_APPLICATION_INFO_QUESTION_DATA_INDEX.PRIMARY_ADDRESS])) {
      isPFApplicationInfoAnswersValid = false;
      setShowLocationError(true, PF_APPLICATION_INFO_QUESTION_DATA_INDEX.PRIMARY_ADDRESS);
    }

    await form?.validateFields();
  } catch (err) {
    isPFApplicationInfoAnswersValid = false;

    const errorFields = await form?.getFieldsError();
    const formErr = errorFields?.find((error: any) => error.errors?.length > 0);
    if (formErr) {
      scrollAndFocusFormItemError(formErr);
    }
  }

  return isPFApplicationInfoAnswersValid;
};

const getAllQuestionsAndModifyPolicyDetailsQuestion = ({
  loading,
  disabled,
  options = [],
  field,
  questionsElements,
}: {
  loading: boolean;
  disabled: boolean;
  options?: IAntdOptions[];
  field: string;
  questionsElements?: IPFApplicationInfoQuestions[];
}) => {
  let clonedQuestionsElements = cloneDeep(questionsElements);
  clonedQuestionsElements = clonedQuestionsElements?.map((applicationInfoQuestionsByType) => {
    if (applicationInfoQuestionsByType.id === PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.POLICY_DETAILS) {
      return {
        ...applicationInfoQuestionsByType,
        questions: applicationInfoQuestionsByType.questions.map((question) => {
          if (question.dataIndex === field) {
            return { ...question, loading, disabled, options };
          }
          return question;
        }),
      };
    }
    return applicationInfoQuestionsByType;
  });
  return clonedQuestionsElements || [];
};

export const handlePFApplicationInfoFormChange = async (value: any, data: any) => {
  const {
    pfApplicationInfoQuestionsForm,
    pfApplicationInfoQuestionsElements,
    updatePFApplicationInfoQuestionByKey,
    fetchCarrierRankingByPolicyTypeId,
  } = usePFApplicationInfoQuestionsStore.getState();
  const { userData } = useProfileV2Store.getState();
  const { isReadOnlyPFApplication } = usePFApplicationStore.getState();
  if (data?.dataIndex === PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EFFECTIVE_DATE) {
    if (dayjs(value)?.isValid()) {
      pfApplicationInfoQuestionsForm?.setFields([
        {
          name: [PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EXPIRATION_DATE],
          value: dayjs(value)?.add(1, 'y'),
          errors: [],
        },
      ]);

      // Logic for not choose expiry date before the effective date
      if (pfApplicationInfoQuestionsElements) {
        const clonedPFApplicationInfoQuestionsElements = cloneDeep(pfApplicationInfoQuestionsElements);
        const policyExpirationIndex = clonedPFApplicationInfoQuestionsElements[
          PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.POLICY_DETAILS
        ].questions.findIndex(
          (question) => question.dataIndex === PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EXPIRATION_DATE,
        );
        if (policyExpirationIndex !== -1) {
          clonedPFApplicationInfoQuestionsElements[PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID.POLICY_DETAILS].questions[
            policyExpirationIndex
          ].disabledDate = (date: dayjs.Dayjs) => {
            return value && date.isBefore(value.add(1, 'd'));
          };

          updatePFApplicationInfoQuestionByKey(
            PF_APPLICATION_INFO_STORE_KEY.PF_APPLICATION_INFO_QUESTIONS_ELEMENTS,
            clonedPFApplicationInfoQuestionsElements,
          );
        }
        //End Logic for not choose expiry date before the effective date
      }
    } else {
      pfApplicationInfoQuestionsForm?.setFields([
        {
          name: [PF_APPLICATION_INFO_QUESTION_DATA_INDEX.EXPIRATION_DATE],
          value: undefined,
          errors: [],
        },
      ]);
    }
  }

  if (data?.dataIndex === PF_APPLICATION_INFO_QUESTION_DATA_INDEX.POLICY_TYPE && !isReadOnlyPFApplication && value) {
    pfApplicationInfoQuestionsForm?.setFields([
      {
        name: [PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARRIER],
        value: '',
        errors: [],
      },
      {
        name: [PF_APPLICATION_INFO_QUESTION_DATA_INDEX.UW_COMPANY_DETAILS_UNIQUE_ID],
        value: '',
        errors: [],
      },
    ]);

    let clonedPFApplicationInfoQuestionsElements = cloneDeep(pfApplicationInfoQuestionsElements);

    // When the policy type is changed, the carrier dropdown should be disabled and the underwriting company dropdown should be disabled
    clonedPFApplicationInfoQuestionsElements = getAllQuestionsAndModifyPolicyDetailsQuestion({
      loading: false,
      disabled: true,
      field: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.UW_COMPANY_DETAILS_UNIQUE_ID,
      questionsElements: clonedPFApplicationInfoQuestionsElements,
    });
    clonedPFApplicationInfoQuestionsElements = getAllQuestionsAndModifyPolicyDetailsQuestion({
      loading: true,
      disabled: true,
      field: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARRIER,
      questionsElements: clonedPFApplicationInfoQuestionsElements,
    });

    updatePFApplicationInfoQuestionByKey(
      PF_APPLICATION_INFO_STORE_KEY.PF_APPLICATION_INFO_QUESTIONS_ELEMENTS,
      clonedPFApplicationInfoQuestionsElements,
    );

    const supportedCarriersOptionsByPolicyType = await fetchCarrierRankingByPolicyTypeId({
      agentId: userData!.agentId,
      policyTypeId: value,
    });

    const supportedCarriersOptions = supportedCarriersOptionsByPolicyType?.[value]?.filter(
      (carrier) =>
        uwCompanyCarrierMapping[carrier.value as keyof typeof uwCompanyCarrierMapping]?.underwritingCompanies?.length >
        0,
    );

    clonedPFApplicationInfoQuestionsElements = getAllQuestionsAndModifyPolicyDetailsQuestion({
      loading: false,
      disabled: false,
      options: supportedCarriersOptions || [],
      field: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARRIER,
      questionsElements: clonedPFApplicationInfoQuestionsElements,
    });

    updatePFApplicationInfoQuestionByKey(
      PF_APPLICATION_INFO_STORE_KEY.PF_APPLICATION_INFO_QUESTIONS_ELEMENTS,
      clonedPFApplicationInfoQuestionsElements,
    );
  }

  if (data?.dataIndex === PF_APPLICATION_INFO_QUESTION_DATA_INDEX.CARRIER && !isReadOnlyPFApplication && value) {
    pfApplicationInfoQuestionsForm?.setFields([
      {
        name: [PF_APPLICATION_INFO_QUESTION_DATA_INDEX.UW_COMPANY_DETAILS_UNIQUE_ID],
        value: '',
        errors: [],
      },
    ]);
    updatePFApplicationInfoQuestionByKey(
      PF_APPLICATION_INFO_STORE_KEY.PF_APPLICATION_INFO_QUESTIONS_ELEMENTS,
      getAllQuestionsAndModifyPolicyDetailsQuestion({
        loading: false,
        disabled: false,
        options: getUnderWritingCompaniesByCarrier(value),
        field: PF_APPLICATION_INFO_QUESTION_DATA_INDEX.UW_COMPANY_DETAILS_UNIQUE_ID,
        questionsElements: pfApplicationInfoQuestionsElements,
      }),
    );
  }
};

export const getPFApplicationInfoApiPayload = (): ICreatePFQuoteInput => {
  const { pfApplicationInfoQuestionsForm, pfLoginConfig } = usePFApplicationInfoQuestionsStore.getState();
  const formAnswers = parseDotSeparatedKeysNestedObject(pfApplicationInfoQuestionsForm?.getFieldsValue());
  const uwCompanyDetailsUniqueId = formAnswers?.policyDetails?.uwCompanyDetailsUniqueId;
  delete formAnswers?.statementDeclaration;
  delete formAnswers?.policyDetails?.uwCompanyDetailsUniqueId;
  const payload = {
    ...formAnswers,
    insuredDetails: {
      ...formAnswers?.insuredDetails,
      phone: deNormalizePhoneNumber(formAnswers?.insuredDetails?.phone),
    },
    policyDetails: {
      ...formAnswers?.policyDetails,
      premium: Number(formAnswers?.policyDetails?.premium),
      tax: formAnswers?.policyDetails?.tax ? Number(formAnswers?.policyDetails?.tax) : undefined,
      fee: formAnswers?.policyDetails?.fee ? Number(formAnswers?.policyDetails?.fee) : undefined,
      effectiveDate: dayjs(formAnswers?.policyDetails?.effectiveDate).format(DATE_FORMAT.MMDDYYYY),
      expirationDate: dayjs(formAnswers?.policyDetails?.expirationDate).format(DATE_FORMAT.MMDDYYYY),
      uwCompanyDetails: uwCompanyCarrierMapping[
        formAnswers?.policyDetails?.carrier as keyof typeof uwCompanyCarrierMapping
      ]?.underwritingCompanies?.find((uwCompany) => uwCompany.uniqueId === uwCompanyDetailsUniqueId),
    },
    pfDetails: {
      ...formAnswers?.pfDetails,
      downPaymentMethod: DownPaymentMethod.BOTH,
      processDownPayment: formAnswers?.pfDetails.processDownPayment === 'true',
      downPaymentPaidBy: DownPaymentPaidBy.INSURED,
    },
    loginConfigs:
      pfLoginConfig?.providerloginConfigs.map((config) => ({
        pfProviderId: config.pfProviderId,
        pfLoginConfigId: config.pfLoginConfigId,
      })) || [],
  } as ICreatePFQuoteInput;
  return payload;
};

export const setFormFieldsPFApplicationInfoQuestion = () => {
  const { pfApplicationInfoQuestionsForm: form } = usePFApplicationInfoQuestionsStore.getState();
  const { pfApplicationData } = usePFApplicationStore.getState();
  const flattenValue = flattenedDotSeparatedKeysNestedObject(
    {
      insuredDetails: pfApplicationData?.insuredDetails,
      policyDetails: {
        ...pfApplicationData?.policyDetails,
        tax: pfApplicationData?.policyDetails?.tax?.toString(),
        fee: pfApplicationData?.policyDetails?.fee?.toString(),
        premium: pfApplicationData?.policyDetails.premium.toString(),
        expirationDate: dayjs(pfApplicationData?.policyDetails.expirationDate),
        effectiveDate: dayjs(pfApplicationData?.policyDetails.effectiveDate),
        uwCompanyDetailsUniqueId: pfApplicationData?.policyDetails?.uwCompanyDetails?.uniqueId,
      },
      pfDetails: {
        ...pfApplicationData?.pfDetails,
        processDownPayment: pfApplicationData?.pfDetails?.processDownPayment?.toString(),
      },
      statementDeclaration: Boolean(pfApplicationData).toString(),
    },
    1,
  );
  form?.setFieldsValue(flattenValue);
};
