import styled from 'styled-components/macro';

export const BindUWStatementsWrapperStyled = styled.div`
  margin: 0 20px;
    a {
    color: ${(p) => p?.theme?.colorScheme?.primary};
    &:hover {
      color: ${(p) => p?.theme?.colorScheme?.primaryHover};
    }
`;
