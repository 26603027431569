/**
 * Parses a flat object with dot-separated keys into a nested object.
 * Iterates over keys, splits them by '.', and constructs a structured object.
 */
type InputObject = Record<string, any>;

type OutputObject = Record<string, any>;

export const parseDotSeparatedKeysNestedObject = (input: InputObject): OutputObject => {
  const output: OutputObject = {};

  for (const key in input) {
    const keys = key.split('.');
    let current = output;

    for (let i = 0; i < keys.length; i++) {
      const part = keys[i];

      if (i === keys.length - 1) {
        current[part] = input[key];
      } else {
        current[part] = current[part] || {};
        current = current[part];
      }
    }
  }

  return output;
};
