import { useEffect } from 'react';

import { PF_QUOTE_STORE_KEYS } from '../pfQuotes/constants';
import { usePFQuotesStore } from '../pfQuotes/store';
import { PF_QUOTE_FAILED_REASONS_MODAL } from './constants';
import { PFQuoteFailedReasonItemStyled, PFQuoteFailedReasonsModalStyled, PFQuoteFailedReasonsStyled } from './styles';

const PFQuoteFailedReasonsModal = () => {
  const { selectedPFQuote, updatePFQuotesByKey } = usePFQuotesStore();
  const { failures = [] } = selectedPFQuote || {};

  useEffect(() => {
    return () => {
      updatePFQuotesByKey(PF_QUOTE_STORE_KEYS.SELECTED_PF_QUOTE, undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PFQuoteFailedReasonsModalStyled title={PF_QUOTE_FAILED_REASONS_MODAL.TITLE} footer={null}>
      <PFQuoteFailedReasonsStyled>
        {failures.map((item, index) => (
          <PFQuoteFailedReasonItemStyled key={`${item.code}-${index}`}>{item.reason}</PFQuoteFailedReasonItemStyled>
        ))}
      </PFQuoteFailedReasonsStyled>
    </PFQuoteFailedReasonsModalStyled>
  );
};

export default PFQuoteFailedReasonsModal;
