import { APPLICATION_SERVICE_ENDPOINTS } from '../../externalServices/helpers/ApiRoutes';
import { JWT_TOKEN } from '../../globalConstants';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { getSelectedAgentProfileId } from '../../utils/getSelectedAgentProfileId';
import { uniqueId } from '../../utils/uniqueId';
import { IRequestCommonHeaders } from './interface';

export const REQUEST_COMMON_HEADERS = (): IRequestCommonHeaders => {
  const accessToken = getFromLocalStorage(JWT_TOKEN.ACCESS_TOKEN);
  const agentProfileId = getSelectedAgentProfileId();

  return {
    authorization: `Bearer ${accessToken}`,
    'content-type': 'application/json',
    'x-request-id': `cfr-${uniqueId()}`,
    'x-agent-profile-id': `${agentProfileId}`,
  };
};

export const EXCLUDED_API_ENDPOINTS = [APPLICATION_SERVICE_ENDPOINTS.GET_POLICY_DOCUMENT, '/quoteProposal'];
