import { Carrier, QuoteMessageCategory } from '@coverforce-platform/cf-common-types';

export const QUOTE_MESSAGES = {
  CARRIER_TIMEOUT: 'The carrier took too long to generate a quote. We shall keep trying in the background.',
  DECLINED: 'The carrier has declined to insure this application.',
  FAILED: 'This carrier is currently experiencing issues returning a quote. Please try again in a few hours.',
};

export const QUOTE_MESSAGE_CATEGORY_WITH_PREMIUM = [
  QuoteMessageCategory.CARRIER_AUTO_REFER,
  QuoteMessageCategory.BRIDGE_TO_CARRIER_PORTAL,
  QuoteMessageCategory.PRIMARY_LOCATION_ONLY_QUOTE,
  QuoteMessageCategory.CARRIER_BLOCKED_MARKET,
];

export const REFERRED_QUOTE_MESSAGE: Partial<{ [key in Carrier]: string }> = {
  [Carrier.AMTRUST]:
    'The quote has been referred to the carrier underwriter. AmTrust usually processes referred quotes within 2 business days. Please contact your underwriter for further details on the status of the quote.',
  [Carrier.GAIG]:
    'The quote has been referred to the carrier underwriter. Great American usually processes referred quotes within 2 business days. Please contact your underwriter for further details on the status of the quote.',
};
