import { TableColumnProps } from 'antd';
import { DefaultTheme } from 'styled-components';

import { MenuConfig } from '../../../components/menuActionButton/interface';
import { MENU_ITEM_KEY, NA, PAGE_ROUTES } from '../../../globalConstants';
import { formatDateTime, getIsApplicationNonEditable } from '../../../helpers/Utils';
import { checkApplicationEligibilityForBound } from '../../../utils/application/application';
import { getApplicationTag } from '../applications/utils';
import { IAccountData, IApplication, PolicyType } from './interface';

export const accountTableColumns = (
  policyTypes: PolicyType,
  theme: DefaultTheme,
): Array<TableColumnProps<IApplication>> => [
  {
    title: 'Policy Type',
    dataIndex: 'PolicyType',
    key: 'policyType',
    render: (_, application) => {
      return application
        ? application.policyDetails && application.policyDetails.length > 0
          ? policyTypes[application.policyDetails[0].policyType]
          : NA
        : NA;
    },
    sorter: (a, b) => {
      return (a ? (a.policyDetails && a.policyDetails.length > 0 ? a.policyDetails[0].policyType : NA) : NA) >
        (b ? (b.policyDetails && b.policyDetails.length > 0 ? b.policyDetails[0].policyType : NA) : NA)
        ? 1
        : -1;
    },
  },
  {
    title: 'Insured',
    dataIndex: 'basicBusinessDetails.businessName',
    key: 'businessName',
    render: (_, application) => {
      return application?.basicBusinessDetails?.businessName || '--';
    },
    sorter: (a, b) => {
      return (a?.basicBusinessDetails?.businessName || '--') > (b?.basicBusinessDetails?.businessName || '--') ? 1 : -1;
    },
  },
  {
    title: 'Created Date',
    dataIndex: 'DateCreated',
    key: 'createdAt',
    render: (_, application) => {
      return formatDateTime(application.createdAt);
    },
    sorter: (a, b) => {
      return (a ? (a.createdAt ? a.createdAt : '') : '') > (b ? (b.createdAt ? b.createdAt : '') : '') ? 1 : -1;
    },
  },
  {
    title: 'Modified Date',
    dataIndex: 'DateUpdated',
    key: 'updatedAt',
    render: (_, application) => {
      return formatDateTime(application.updatedAt);
    },
    sorter: (a, b) => {
      return (a ? (a.updatedAt ? a.updatedAt : '') : '') > (b ? (b.updatedAt ? b.updatedAt : '') : '') ? 1 : -1;
    },
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'applicationStatus.statusCode',
    sorter: (a, b) => {
      return (a ? (a.applicationStatus ? a.applicationStatus.statusCode?.toLowerCase() : NA) : NA) >
        (b ? (b.applicationStatus ? b.applicationStatus.statusCode?.toLowerCase() : NA) : NA)
        ? 1
        : -1;
    },
    render: (_, application) => {
      return (
        <>
          {application.applicationStatus ? (
            getApplicationTag({
              theme,
              applicationId: application?.applicationId,
              applicationStatus: application?.applicationStatus?.statusCode,
              applicationTags: application?.applicationTags || [],
            })
          ) : (
            <></>
          )}
        </>
      );
    },
  },
];

export const menuConfig = (application: IApplication): MenuConfig[] => [
  {
    label: 'Edit Application',
    key: MENU_ITEM_KEY.EDIT,
    disabled: getIsApplicationNonEditable(application?.applicationStatus),
    link: !getIsApplicationNonEditable(application?.applicationStatus)
      ? `${PAGE_ROUTES.APPLICATION}${application.applicationId}`
      : undefined,
  },
  {
    label: 'View Application',
    key: MENU_ITEM_KEY.VIEW,
    showItem: getIsApplicationNonEditable(application?.applicationStatus),
    link: `${PAGE_ROUTES.APPLICATION}${application.applicationId}`,
  },
  {
    label: 'Clone Application',
    key: MENU_ITEM_KEY.CLONE,
  },
  {
    label: 'Mark As Bound',
    key: MENU_ITEM_KEY.MARK_AS_BOUND,
    showItem: checkApplicationEligibilityForBound(application?.applicationStatus?.statusCode!),
  },
  {
    label: 'Delete Application',
    key: MENU_ITEM_KEY.DELETE,
    extraData: { danger: 'danger' },
  },
];

export const message = {
  accountUpdateSuccess: 'Account updated successfully!',
  accountDeleteSuccess: 'Account deleted successfully!',
  applicationDeleteSuccess: 'Application deleted successfully!',
};

export const ACORD_IN_PROGRESS_MESSAGE =
  'ACORD form extraction in progress. Please click back in 3-5 minutes to edit this application.';

export const GA_CATEGORY = {
  ACCOUNT_SIDE_BAR: 'accountDetails_sideBar',
  ACCOUNT_EDIT_DRAWER: 'accountDetails_editDrawer',
  ACCOUNT_DELETE_CONFIRM_BOX: 'accountDetails_deleteConfirmBox',
  ACCOUNT_CLONE_CONFIRM_BOX: 'accountDetails_cloneConfirmBox',
  ACCOUNT_APPLICATION_TABLE: 'accountDetails_applicationTable',
  ACCOUNT_APPLICATION_DELETE_CONFIRM_BOX: 'accountDetails_applicationDeleteConfirmBox',
  MARK_AS_BOUND_APPLICATION_MODAL: 'applications_markAsBoundApplicationModal',
  ACCOUNT_HEADER: 'accountDetails_header',
  ACCOUNT_NEW_QUOTE_MODAL: 'accountDetails_newQuoteModal',
};

export const GA_ACTION = {
  BACK_TO_ACCOUNTS_BTN_CLICK: 'backToAccountsBtn_click',
  EDIT_ACCOUNT_BTN_CLICK: 'editAccountBtn_click',
  DELETE_ACCOUNT_BTN_CLICK: 'deleteAccountBtn_click',
  CLOSE_DELETE_ACCOUNT_CONFIRM_BOX_BTN_CLICK: 'closeDeleteAccountConfirmBoxBtn_click',
  NEW_QUOTE_BTN_CLICK: 'newQuoteBtn_click',
  EDIT_APPLICATION_BTN_CLICK: 'editApplicationBtn_click',
  VIEW_APPLICATION_BTN_CLICK: 'viewApplicationBtn_click',
  VIEW_APPLICATION_BY_ROW_CLICK: 'viewApplicationByRow_click',
  CLONE_APPLICATION_BTN_CLICK: 'cloneApplicationBtn_click',
  MARK_AS_BOUND_BTN_CLICK: 'markAsBoundBtn_click',
  DELETE_APPLICATION_BTN_CLICK: 'deleteApplicationBtn_click',
  CLOSE_DELETE_APPLICATION_CONFIRM_BOX_BTN_CLICK: 'closeDeleteApplicationConfirmBoxBtn_click',
  CLOSE_MARK_AS_BOUND_MODAL: 'closeMarkAsBoundAppModalBtn_click',
};

export const GA_LABEL = {
  EDIT_ACCOUNT_SIDE_BAR: 'Edit account from side bar',
  EDIT_ACCOUNT_DRAWER: 'Edit account in drawer',
  EDIT_APPLICATION_MENU_ITEM: 'Edit application in menu item',
  DELETE_ACCOUNT_CONFIRM_BOX: 'Delete account in confirm box',
  DELETE_APPLICATION_CONFIRM_BOX: 'Delete application in confirm box',
  DELETE_APPLICATION_MENU_ITEM: 'Delete application in menu item',
  CLOSE_ACCOUNT_CONFIRM_BOX: 'Close account delete in confirm box',
  CLOSE_APPLICATION_CONFIRM_BOX: 'Close application delete in confirm box',
  DELETE_ACCOUNT_SIDE_BAR: 'Delete account from side bar',
  BACK_TO_ACCOUNTS: 'Back to accounts page',
  NEW_ACCOUNT: 'New Account btn click',
  VIEW_APPLICATION_BTN: 'View application using menu item',
  VIEW_APPLICATION_BY_ROW: 'View application by row click',
  CLONE_APPLICATION: 'Clone application btn click using menu item',
  MARK_AS_BOUND_APPLICATION: 'Mark as bound application using menu item',
};

export const ACCOUNT_STORE_KEYS: { [key: string]: keyof IAccountData } = {
  APPLICATIONS: 'applications',
};

export const ACORD_IN_PROGRESS_POLLING_INTERVAL_MS = 5000;
