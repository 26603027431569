export const BIND_UW_STATEMENT_MODAL_MESSAGES = {
  FETCH_BIND_UW_STATEMENTS_ERROR: 'Failed to get bind underwriting statements',
};

export const BIND_UW_STATEMENT_STORE_META_DATA = {
  STORE_NAME: 'Bind UW Statement Store',
  ANONYMOUS_ACTION_NAME: 'BIND_UW_STATEMENT_ACTION',
  BIND_UW_STATEMENT_LOADING: 'BIND_UW_STATEMENT_LOADING',
  FETCHED_BIND_UW_STATEMENT_DATA: 'FETCHED_BIND_UW_STATEMENT_DATA',
  CLEAR_BIND_UW_STATEMENT_STORE: 'CLEAR_BIND_UW_STATEMENT_STORE',
};
