import { ApplicationTag, PolicyType } from '@coverforce-platform/cf-common-types';

import { REACT_APP_AUDIENCE_AUTH0 } from './helpers/EnvironmentVariables';

export const JWT_TOKEN = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  SUB: 'sub',
  AUTHORIZATION_CODE: 'authorization_code',
};

export const DEFAULT_PAGINATION = {
  PAGE: 1,
  SIZE: 10,
};

export const CUSTOM_FRONTEND_ERROR = {
  ERROR_CODE: 'FRONTEND_CUSTOM_ERROR',
  ERROR_DETAILS: 'This error is thrown intentionally by frontend',
};

export const PAGE_SIZE = 1000;

export const AUTH_TOKENS = [JWT_TOKEN.ACCESS_TOKEN, JWT_TOKEN.REFRESH_TOKEN];

export const TIME_VALUES = {
  YEAR: 365,
  HOURS: 24,
  MINUTES: 60,
  SECONDS: 60,
  MILLISECONDS: 1000,
};

export const SAME_SITE_COOKIE = {
  STRICT: 'strict',
  LAX: 'lax',
  NONE: 'none',
};

export const HTTPS_CODES = {
  UNAUTHENTICATED: 401,
  UNAUTHORIZED: 403,
  TIMED_OUT: 0,
};

export const DEFAULT_API_ERROR_MESSAGE = 'Something went wrong, please try again in some time!';

export const INPUT_TYPES = {
  TEL: 'tel',
  LOCATION: 'location',
  PASSWORD: 'password',
  SELECT: 'select',
  EMAIL: 'email',
  NATIONWIDE_PRODUCER_CODE: 'nationwide_producer_code',
  GAIG_PRODUCER_CODE: 'gaig_producer_code',
  NUMBER: 'number',
};

export const SPIN_SIZE = {
  SMALL: 'small',
  DEFAULT: 'default',
  LARGE: 'large',
} as const;

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACTION: 'action',
  DELETE: 'delete',
  FILE: 'file',
  DEFAULT: 'default',
} as const;

export const FEATURE_FLAG = {
  WEBAPP_THEME: 'webapp-theme',
  GOOGLE_ANALYTICS: 'google-analytics',
  SENTRY_INTEGRATION: 'sentry-integration',
  LOG_ROCKET_INTEGRATION: 'log-rocket-integration',
  ALLOWED_POLICIES: 'allowed-policies',
  CREATE_APPLICATION_FROM_ACORD: 'create-application-from-acord',
  APPLICATION_CREATION_ACORD_CONFIG: 'application-creation-acord-config',
  NETWORK_MGMT_AGENCY_CARRIERS_ACCESS: 'network-mgmt-agency-carriers-access',
  SSO_CLIENT_REDIRECT_URL: 'sso-client-redirect-url',
  REDESIGN_LOCATION_TAB: 'webapp-redesign-application-location-tab',
  DEFAULT_UW_ANSWERS_ALLOWED: 'default-uw-answers-allowed',
  KNOCK_IN_APP_MESSAGE: 'knock-in-app-message',
  KNOCK_WEB_PUSH_NOTIFICATIONS: 'knock-web-push-notifications',
  PREMIUM_FINANCE_ENABLED: 'premium-finance-enabled',
  BIBERK_TILE_ENABLED: 'biberk-tile-enabled',
  QUOTE_COMPARISON: 'quote-comparison',
  AGENT_AGENCY_STATUS_DISPLAY: 'agent-agency-status-display',
};

export const ZUSTAND_STORE_KEYS = {
  APP_LOADING: 'appLoading',
  ACTIVE_TAB: 'activeTab',
};

export const QUESTION_TYPE = {
  MONEY: 'money',
  RADIO: 'radio',
  DROPDOWN: 'dropdown',
  NUMBER: 'number',
  DATE: 'date',
  YEAR: 'year',
  MONTH: 'month',
  STRING: 'string',
  PHONE: 'phone',
};

export const CONDITION_PROPS = {
  CURRENT_VALUE: '[CURRENT_VALUE]',
  CURRENT_YEAR: '[CURRENT_YEAR]',
};

export const UNAUTHENTICATED_LOGIN_ROUTES = ['/login', '/login/'];
export const UNAUTHENTICATED_LOGOUT_ROUTES = ['/logout', '/logout/'];
export const UNAUTHENTICATED_LOGIN_LOGOUT_ROUTES = [...UNAUTHENTICATED_LOGIN_ROUTES, ...UNAUTHENTICATED_LOGOUT_ROUTES];
export const UNAUTHENTICATED_REGISTRATION_ROUTE = '/registration/';
export const UNAUTHENTICATED_PAYMENT_IFRAME_ROUTE_PART = '/iframe/return-url';

export const GLOBAL_LOCALE = {
  US: 'en-US',
};

export const UNSUPPORTED_STATES = ['WA', 'ND', 'OH', 'WY'];

export const UploadStatus = {
  UPLOADING: 'uploading',
  DONE: 'done',
  ERROR: 'error',
};

export const POPUP_TYPE = {
  NEW_QUOTE: 'NEW_QUOTE',
  NEW_QUOTE_V2: 'NEW_QUOTE_V2',
  CONTACT_US: 'CONTACT_US',
  WC_MONOLISTIC_STATES: 'WC_MONOLISTIC_STATES',
  ISU_BLUELIGHT_SPECIAL: 'ISU_BLUELIGHT_SPECIAL',
  TEAM_MEMBER_DELETE_CONFIRMATION: 'TEAM_MEMBER_DELETE_CONFIRMATION',
  BROKER_CONFIG_DELETE_CONFIRMATION: 'BROKER_CONFIG_DELETE_CONFIRMATION',
  BROKER_CONFIG_ENABLE_OR_DISABLE: 'BROKER_CONFIG_ENABLE_OR_DISABLE',
  ACCOUNTS_DELETE_CONFIRMATION: 'ACCOUNTS_DELETE_CONFIRMATION',
  RESET_PASSWORD_CONFIRMATION: 'RESET_PASSWORD_CONFIRMATION',
  ACCOUNT_DELETE_CONFIRMATION: 'ACCOUNT_DELETE_CONFIRMATION',
  APPLICATION_DELETE_CONFIRMATION: 'APPLICATION_DELETE_CONFIRMATION',
  PF_APPLICATION_DELETE_CONFIRMATION: 'PF_APPLICATION_DELETE_CONFIRMATION',
  POLICY_LOSS_MODAL: 'POLICY_LOSS_MODAL',
  CLONE_APPLICATION: 'CLONE_APPLICATION',
  APPLICATION_BIND_CONFIRMATION: 'APPLICATION_BIND_CONFIRMATION',
  FIND_NAICS_BY_SIC: 'FIND_NAICS_BY_SIC',
  DELETE_CARRIER_FROM_APPLICATION: 'DELETE_CARRIER_FROM_APPLICATION',
  AGENCY_DELETE_CONFIRMATION: 'AGENCY_DELETE_CONFIRMATION',
  WHATS_NEW_LEARN_MORE: 'WHATS_NEW_LEARN_MORE',
  REASSIGN_APPLICATION: 'REASSIGN_APPLICATION',
  PAST_EFFECTIVE_DATE_MODAL: 'PAST_EFFECTIVE_DATE_MODAL',
  INDUSTRY_SELECTION_MODAL: 'INDUSTRY_SELECTION_MODAL',
  MARK_AS_BOUND_MODAL: 'MARK_AS_BOUND_MODAL',
  FAILED_REASONS: 'FAILED_REASONS',
  PF_QUOTE_FAILED_REASONS: 'PF_QUOTE_FAILED_REASONS',
  TIMEOUT_REASONS: 'TIMEOUT_REASONS',
  DECLINED_REASONS: 'DECLINED_REASONS',
  POLICY_DECLINED_REASONS: 'POLICY_DECLINED_REASONS',
  REFER_QUOTE: 'REFER_QUOTE',
  MARK_AS_BOUND: 'MARK_AS_BOUND',
  REQUEST_TO_BIND: 'REQUEST_TO_BIND',
  CANCEL_REQUEST_TO_BIND: 'CANCEL_REQUEST_TO_BIND',
  REQUEST_TO_BIND_NOTES: 'REQUEST_TO_BIND_NOTES',
  OWNER_OFFICER_DETAILS_MODAL: 'OWNER_OFFICER_DETAILS_MODAL',
  EDIT_BIND_ACTIONS_MODAL: 'EDIT_BIND_ACTIONS_MODAL',
  DOWNLOAD_ACORD_MODAL: 'DOWNLOAD_ACORD_MODAL',
  APPLICATION_DOCUMENT_CENTER_MODAL: 'APPLICATION_DOCUMENT_CENTER_MODAL',
  MODIFIERS_MODAL: 'MODIFIERS_MODAL',
  DELETE_SCHEDULED_REPORTING_CONFIRMATION: 'DELETE_SCHEDULED_REPORTING_CONFIRMATION',
  GENERATE_REPORT_MODAL: 'GENERATE_REPORT_MODAL',
  SCHEDULE_REPORT_MODAL: 'SCHEDULE_REPORT_MODAL',
  ADDITIONAL_INTEREST_MODAL: 'ADDITIONAL_INTEREST_MODAL',
  PAYMENT_FEE_BREAKUP_MODAL: 'PAYMENT_FEE_BREAKUP_MODAL',
  PAYMENT_IFRAME_MODAL: 'PAYMENT_IFRAME_MODAL',
  DELETE_NETWORK_CONTACT_CONFIRMATION: 'DELETE_NETWORK_CONTACT_CONFIRMATION',
  SCHEDULE_DEMO_MODAL: 'SCHEDULE_DEMO_MODAL',
  QUOTE_COMPARISON_MODAL: 'QUOTE_COMPARISON_MODAL',
  AGENCY_STATUS_CHANGE_CONFIRMATION: 'AGENCY_STATUS_CHANGE_CONFIRMATION',
  AGENT_STATUS_CHANGE_CONFIRMATION: 'AGENT_STATUS_CHANGE_CONFIRMATION',
  PAYMENT_UW_STATEMENT_MODAL: 'PAYMENT_UW_STATEMENT_MODAL',
};

export const AUTH0_GET_ACCESS_TOKEN_SILENTLY_PARAMS = {
  audience: REACT_APP_AUDIENCE_AUTH0,
  // scope: REACT_APP_SCOPES_AUTH0,
  detailedResponse: true,
};

export const USState: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  DC: 'District Of Columbia',
};

export const LOCAL_STORAGE_KEYS = {
  SSO_REDIRECT_URL: 'ssoRedirectUrl',
  SSO_LOGOUT_URL: 'ssoLogoutUrl',
  APPLICATIONS_COLUMNS: 'applicationsColumns',
  APPLICATIONS_FILTERS: 'applicationsFilters',
  PF_QUOTES_FILTERS: 'premiumFinanceQuotesFilters',
  PF_QUOTES_COLUMNS: 'premiumFinanceQuotesColumns',
  LOGIN_TYPE: 'loginType',
};

export const LOCAL_STORAGE_VALUES = {
  SSO: 'sso',
};

export const SESSION_STORAGE_KEYS = {
  APP_THEME: 'app_theme',
  CLIENT_APP: 'client_app',
  PROFILE_ID: 'profileId', // Value should be exactly same as QUERY_PARAMS.PROFILE_ID
  SSO_REDIRECT_URL: 'ssoRedirectUrl',
  SSO_LOGOUT_URL: 'ssoLogoutUrl',
};

export const DRAWER_TYPE = {
  PROFILE_EDIT: 'PROFILE_EDIT',
  AGENT_ADD: 'AGENT_ADD',
  AGENT_EDIT: 'AGENT_EDIT',
  ACCOUNT_ADD: 'ACCOUNT_ADD',
  ACCOUNT_EDIT: 'ACCOUNT_EDIT',
  BROKER_CONFIGURATION_ADD: 'BROKER_CONFIGURATION_ADD',
  BROKER_CONFIGURATION_EDIT: 'BROKER_CONFIGURATION_EDIT',
  ACCOUNT_DETAILS_EDIT: 'ACCOUNT_DETAILS_EDIT',
  AMTRUST_APPOINTMENT: 'AMTRUST_APPOINTMENT',
  BIBERK_APPOINTMENT: 'BIBERK_APPOINTMENT',
  AGENCY_ADD: 'AGENCY_ADD',
  QUOTE_DETAILS: 'QUOTE_DETAILS',
  PF_QUOTE_DETAILS: 'PF_QUOTE_DETAILS',
  NETWORK_CONTACT_ADD: 'NETWORK_CONTACT_ADD',
  NETWORK_CONTACT_EDIT: 'NETWORK_CONTACT_EDIT',
};

export const MENU_ITEM_KEY = {
  EDIT: 'edit',
  DELETE: 'delete',
  DEFAULT: 'default',
  CLONE: 'clone',
  VIEW: 'view',
  MARK_AS_BOUND: 'markAsBound',
  REASSIGN: 'reassign',
  DISABLE: 'disable',
  ENABLE: 'enable',
  EDIT_BIND_ACTIONS: 'editBindActions',
};

export const STATUS_MENU_ITEM_KEY = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  REJECTED: 'REJECTED',
  DEACTIVATED: 'DEACTIVATED',
};

export const NA = 'N/A';

export const PAGE_ROUTES = {
  ROOT: '/',
  ACCOUNTS: '/accounts/',
  APPLICATION: '/application/',
  LOGIN: '/login',
  APPLICATIONS: '/applications',
  PF_APPLICATIONS: '/premium-finance/applications',
  PF_APPLICATION: '/premium-finance/application/',
  AGENCIES: '/agencies',
  AGENCY_BROKER_CONFIG: '/settings/brokerconfig',
  AGENCY_TEAM_MANAGEMENT: '/settings/team',
  REPORT_CENTER: '/report-center',
  APPETITE: '/appetite',
};

export const supportEmail = 'support@coverforce.com';

export const USER_ROLE_DISPLAY_NAME = {
  AGENT: 'Agent',
  AGENCY_ADMIN: 'Admin',
  AGENCY_NETWORK_USER: 'Network User',
};

// eslint-disable-next-line no-shadow
export enum Actions {
  ADD = 'add',
  EDIT = 'edit',
}

export const APPLICATIONS_DB_UPDATE_DELAY = 10000;

export const POLICY_NAME_MAPPING: { [key: string]: string } = {
  [PolicyType.WC]: "Worker's Compensation",
  [PolicyType.BOP]: "Business Owner's Policy",
  [PolicyType.CGL]: 'General Liability',
  [PolicyType.CYBER]: 'Cyber',
  [PolicyType.CAUTO]: 'Commercial Auto',
  [PolicyType.BR]: `Builder's Risk`,
  [PolicyType.MPL]: `Miscellaneous Professional Liability`,
};

export const QUERY_PARAMS = {
  CLIENT_APP: 'clientApp',
  RETURN_TO: 'returnTo',
  CF_SSO_CLIENT_ID: 'cfSSOClientId',
  JWT_TOKEN: 'jwtToken',
  IS_LOGOUT: 'isLogout',
  PROFILE_ID: 'profileId', // Value should be exactly same as SESSION_STORAGE_KEYS.PROFILE_ID
};

export const API_ERROR_CODE = {
  CF_INTERNAL_SERVER_ERROR: 'CF_INTERNAL_SERVER_ERROR',
};

export const ENVIRONMENT = {
  DEV: 'dev',
  STAGING: 'staging',
  SNP: 'snp',
  PROD: 'prod',
};

export const NAVBAR_ID = 'navbar_container';

export const MIME_TYPES = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TXT: 'text/plain',
  ZIP: 'application/zip',
  JPEG: 'image/jpeg', // This also covers jpg
  PNG: 'image/png',
};

export const PAGE_NAME = {
  ACCOUNT_DETAIL: 'ACCOUNT_DETAIL',
  APPLICATIONS: 'APPLICATIONS',
  APPLICATION: 'APPLICATION',
  UNKNOWN: 'UNKNOWN',
};

export const ALLOWED_APPLICATION_TAGS = [
  ApplicationTag.BIND_REQUESTED,
  ApplicationTag.MARKED_BOUND,
  ApplicationTag.REASSIGNED,
];

export const SSO_CLIENT_APP_PREFIX = 'sso_';

export const FALSY_VALUES = [undefined, null, ''];

export const DATE_FORMAT = {
  YYYYMMDD: 'YYYY-MM-DD',
  MMDDYYYY: 'MM-DD-YYYY',
  YYYY: 'YYYY',
  DDMMMYYYY: 'DD-MMM-YYYY',
};

export const EVENT_LISTENERS = {
  STORAGE: 'storage',
  MESSAGE: 'message',
};

export const TableActions = {
  PAGINATE: 'paginate',
  FILTER: 'filter',
  SORT: 'sort',
};

export const helpCenterLink = 'https://helpcenter.coverforce.com';

export const PUBLIC_ROUTE = '/public/';

export const PROMISE_RESULT_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};
