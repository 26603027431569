import {
  IApplicationBindRequestInput,
  ICancelApplicationBindRequestInput,
  ICompareQuotesInput,
  ICompareQuotesOutput,
  IGetApplicationInput,
  IGetApplicationLiabilityLimitsOutput,
  IGetISUCarrierDetailsInput,
  IGetISUCarrierDetailsOutput,
  IGetQuoteListInput,
  IGetQuoteListOutput,
  IGetQuoteProposalInput,
  IGetQuoteProposalOutput,
  IReferQuoteInput,
  IReferQuoteOutput,
  IReSubmitForQuotesInput,
  ISubmitForQuotesInput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosApplicationHelperService from '../../axios/applicationHelperService/instance';
import axiosApplicationService from '../../axios/applicationService/instance';
import { useQuotesStore } from '../../components/quotes/store';
import { QUOTES_STORE_KEYS } from '../../constants/quotesConstants';
import { IPollForQuotes } from '../../globalInterface';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { cloneDeep } from '../../utils/lodash';
import sleep from '../../utils/sleep';
import { APPLICATION_SERVICE_ENDPOINTS, HELPER_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const createQuotes = async (params: ISubmitForQuotesInput) => {
  const createQuotesApiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.CREATE_OR_REGENERATE_QUOTES,
  });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(createQuotesApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const regenerateQuote = async (params: IReSubmitForQuotesInput) => {
  const regenerateQuoteApiUrl = createQueryUrl({
    url: APPLICATION_SERVICE_ENDPOINTS.CREATE_OR_REGENERATE_QUOTES,
  });

  try {
    const response: AxiosResponse = await axiosApplicationService.put(regenerateQuoteApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const pollForQuotes = async (params: IGetQuoteListInput, pollingData: IPollForQuotes): Promise<IGetQuoteListOutput> => {
  const { quotesData: quotesDataFromStore, updateQuotesByKey } = useQuotesStore.getState();
  const getQuotesApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_QUOTES_BY_APPLICATION_ID(params) });

  const { totalQuotes, regenerateQuoteCarrierId, frequencyInMs = 3000, maxRetries = 300 } = pollingData;
  let retries = 0,
    quotesData: IGetQuoteListOutput = { applicationId: params.applicationId, quotes: [] };

  // Setting quotesData to existing data in store filtering out carrier quote for which regenerate quote is requested (liability limit changed)
  if (regenerateQuoteCarrierId) {
    quotesData.quotes = cloneDeep(quotesDataFromStore.filter((item) => item.carrierId !== regenerateQuoteCarrierId));
  }

  do {
    await sleep(frequencyInMs);
    try {
      const response: AxiosResponse = await axiosApplicationService.get(getQuotesApiUrl);
      // With CFP-6040 discussion, we shall now update all quotes data in case of liability limits change for any quote (regenerateQuoteCarrierId existing)
      updateQuotesByKey(QUOTES_STORE_KEYS.QUOTES_DATA, response?.data?.quotes);
      quotesData = response?.data;
      retries++;
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.errors);
    }
  } while (
    quotesData?.quotes.length !== totalQuotes &&
    retries <= maxRetries &&
    window.location.pathname.includes(params.applicationId)
  );

  return quotesData;
};

const getQuotes = async (params: IGetQuoteListInput): Promise<IGetQuoteListOutput> => {
  const getQuotesApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_QUOTES_BY_APPLICATION_ID(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getQuotesApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getIsuClpaDetails = async (params: IGetISUCarrierDetailsInput): Promise<IGetISUCarrierDetailsOutput> => {
  const getIsuClpaDetailsUrl = createQueryUrl({ url: HELPER_SERVICE_ENDPOINTS.GET_ISU_CLPA_DETAILS });

  try {
    const response: AxiosResponse = await axiosApplicationHelperService.post(getIsuClpaDetailsUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getQuoteProposal = async (params: IGetQuoteProposalInput): Promise<IGetQuoteProposalOutput> => {
  const getQuoteProposalApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_QUOTE_PROPOSAL(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getQuoteProposalApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const getLiabilityLimits = async (params: IGetApplicationInput): Promise<IGetApplicationLiabilityLimitsOutput> => {
  const getLiabilityLimitsApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.GET_LIABILITY_LIMITS(params) });

  try {
    const response: AxiosResponse = await axiosApplicationService.get(getLiabilityLimitsApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const referQuote = async (params: IReferQuoteInput): Promise<IReferQuoteOutput> => {
  const referQuoteApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.REFER_QUOTE });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(referQuoteApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const markAsBoundQuote = async (params: any) => {
  const markAsBoundQuoteApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.MARK_AS_BOUND_QUOTE });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(markAsBoundQuoteApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const requestToBindQuote = async (params: IApplicationBindRequestInput) => {
  const requestToBindQuoteApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.REQUEST_TO_BIND });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(requestToBindQuoteApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const cancelRequestToBindQuote = async (params: ICancelApplicationBindRequestInput) => {
  const cancelRequestToBindQuoteApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.CANCEL_REQUEST_TO_BIND });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(cancelRequestToBindQuoteApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const compareQuotes = async (params: ICompareQuotesInput): Promise<ICompareQuotesOutput> => {
  const compareQuotesApiUrl = createQueryUrl({ url: APPLICATION_SERVICE_ENDPOINTS.COMPARE_QUOTES });

  try {
    const response: AxiosResponse = await axiosApplicationService.post(compareQuotesApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export {
  createQuotes,
  regenerateQuote,
  getQuotes,
  pollForQuotes,
  getIsuClpaDetails,
  getQuoteProposal,
  getLiabilityLimits,
  referQuote,
  markAsBoundQuote,
  requestToBindQuote,
  cancelRequestToBindQuote,
  compareQuotes,
};
