import { cloneDeep } from '../../utils/lodash';
import { useConfigurationStore } from '../configuration/store';
import { NOTIFICATIONS_STORE_KEYS } from '../notifications/constants';
import { useNotificationsStore } from '../notifications/store';
import {
  MarkAsReadIconStyled,
  MarkAsReadTextStyled,
  MarkAsReadWrapperStyled,
  NotificationHeaderStyled,
  NotificationTitleIconStyled,
  NotificationTitleLinkStyled,
} from './styles';

const NotificationsHeader = () => {
  const { feedClient } = useConfigurationStore();
  const { notificationsData, updateNotificationsByKey } = useNotificationsStore();

  const unreadCount = feedClient?.store?.getState()?.metadata?.unread_count || 0;

  const onMarkAllAsReadClick = () => {
    feedClient?.markAllAsRead();
    const clonedNotificationsData = cloneDeep(notificationsData);
    clonedNotificationsData.forEach((item) => (item.read_at = new Date().toISOString()));
    updateNotificationsByKey(NOTIFICATIONS_STORE_KEYS.NOTIFICATIONS_DATA, clonedNotificationsData);
  };

  return (
    <NotificationHeaderStyled>
      {notificationsData?.length > 0 && (
        <MarkAsReadWrapperStyled onClick={onMarkAllAsReadClick} unreadCount={unreadCount}>
          <MarkAsReadTextStyled>Mark all as read</MarkAsReadTextStyled>
          <MarkAsReadIconStyled />
        </MarkAsReadWrapperStyled>
      )}
      <NotificationTitleLinkStyled id='link_notification_settings' href='/notification-settings' stopPropagation={true}>
        Notifications Settings
        <NotificationTitleIconStyled />
      </NotificationTitleLinkStyled>
    </NotificationHeaderStyled>
  );
};

export default NotificationsHeader;
