import {
  ApplicationStatus,
  Carrier,
  PaymentInstrumentType,
  PaymentIntegrationMethod,
  PaymentPlanType,
} from '@coverforce-platform/cf-common-types';

import { useApplicationStore } from '../../pages/v2/application/store';
import { isEmpty } from '../../utils/lodash';
import { usePaymentStore } from '../payment/store';
import { isPaymentDetailsValidUtil } from '../payment/utils';
import { useQuotesStore } from '../quotes/store';
import {
  DISABLED_PAYMENT_PLAN_CARRIERS,
  PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX,
  PAYMENT_CTA_TEXT,
  PAYMENT_WIDGET_FORM_CONFIG,
} from './constants';

// NOTE: This function extends handling of isReadOnlyModeAppEnable(). Application is readonly mode in BOUND, REFER
// But in case of application status QUOTED/REFERRED, we need to enabled the bind flow in case non-deferred payment is completed via BIND NOW and only policy needs to be bound.
// In REFERRED now user can Bind the Policy on a quote as it's no longer a status which signifies the end of application
export const isPaymentWidgetDisabled = () => {
  const { applicationData, isReadOnlyApplication } = useApplicationStore.getState();
  const { applicationStatus } = applicationData || {};
  const { statusCode } = applicationStatus || {};
  return (
    isReadOnlyApplication &&
    ![ApplicationStatus.QUOTED, ApplicationStatus.REFERRED, ApplicationStatus.BIND_PENDING].includes(statusCode!)
  );
};

export const isPreSelectPaymentPlanButtonDisabled = () => {
  const { paymentForm, paymentPlans } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  const selectedPaymentPlan = paymentPlans?.find(
    (plan) => plan?.cfPaymentPlanId === paymentForm?.getFieldValue(PAYMENT_WIDGET_FORM_CONFIG.paymentPlan.name),
  );
  const isPaymentAdditionalDetailsRequired = isEmpty(selectedPaymentPlan?.additionalDetails) ? false : true;
  const selectedPaymentAdditionalDetails = selectedPaymentPlan?.additionalDetails?.map((additionalDetail) => {
    const additionalDetailAnswer = paymentForm?.getFieldValue(
      `${PAYMENT_ADDITIONAL_DETAILS_FORM_ITEM_PREFIX}_${additionalDetail?.additionalDetailCategory}`,
    );
    return {
      additionalDetailCategory: additionalDetail?.additionalDetailCategory,
      additionalDetailAnswer: additionalDetailAnswer?.toString(),
    };
  });
  const isAllPaymentAdditionalDetailsSelected = selectedPaymentAdditionalDetails?.every(
    (item) => item?.additionalDetailAnswer,
  );

  return (
    (!DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '') && !selectedPaymentPlan) ||
    (isPaymentAdditionalDetailsRequired && !isAllPaymentAdditionalDetailsSelected)
  );
};

export const getCarrierShortName = (carrierId?: Carrier) => {
  if (!carrierId) {
    return '';
  }
  const { selectedCarriersInfo } = useApplicationStore.getState();

  const selectedCarrier = selectedCarriersInfo?.find((carrier) => carrier?.carrierId === carrierId);
  const { carrierShortName } = selectedCarrier || {};
  return carrierShortName || '';
};

export const getPreSelectPaymentPlanCTAText = () => {
  const { applicationData } = useApplicationStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  return `${
    applicationData?.applicationStatus?.statusCode === ApplicationStatus.BIND_PENDING ||
    DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '')
      ? PAYMENT_CTA_TEXT.BIND_POLICY
      : PAYMENT_CTA_TEXT.CONFIRM_PAYMENT_PLAN
  }`;
};

export const getPostSelectPaymentPlanCTAText = () => {
  return PAYMENT_CTA_TEXT.BIND_POLICY;
};

export const handlePostSelectPaymentPlanCTADisable = () => {
  const { selectedQuote } = useQuotesStore.getState();
  const { paymentForm, selectedPaymentPlanId, paymentPlans, paymentOptionsData } = usePaymentStore.getState();

  const selectedPaymentPlan = paymentPlans?.find((plan) => plan?.cfPaymentPlanId === selectedPaymentPlanId);

  return (
    ((isCFCreditCardPaymentMethodVisible() && !isPaymentDetailsValidUtil({ form: paymentForm! })) ||
      isPaymentWidgetDisabled() ||
      (selectedPaymentPlan?.paymentPlanType === PaymentPlanType.NON_DEFERRED && !paymentOptionsData)) &&
    !DISABLED_PAYMENT_PLAN_CARRIERS.includes(selectedQuote?.carrierId || '')
  );
};

export const isPremiumBreakUpVisible = () => {
  // Once we integrate more carrier premium break up then this condition will be updated/not needed, will be decided according to the requirement.
  const { selectedPaymentPlanId } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();
  return selectedPaymentPlanId && [Carrier.COTERIE, Carrier.HISCOX].includes(selectedQuote?.carrierId!);
};

export const isCFCreditCardPaymentMethodVisible = () => {
  const { isPaymentPlanConfirmed, paymentOptionsData } = usePaymentStore.getState();
  return (
    isPaymentPlanConfirmed &&
    [PaymentIntegrationMethod.API, PaymentIntegrationMethod.TOKENIZE_INSTRUMENT].includes(
      paymentOptionsData?.paymentOptions?.[0]?.paymentIntegrationMethods?.[0]!,
    ) &&
    paymentOptionsData?.paymentOptions?.[0]?.paymentInstrumentType === PaymentInstrumentType.CREDIT_CARD
  );
};
