import { AgentPortalUserType } from '@coverforce-platform/cf-common-types';

import AccountsSvg from '../../assets/svgs/accountsSvg';
import AppetiteSvg from '../../assets/svgs/appetiteSvg';
import DownArrowSvg from '../../assets/svgs/downArrow';
import HelpSvg from '../../assets/svgs/helpSvg';
import HomeSvg from '../../assets/svgs/homeSvg';
import ReportsCenterSvgMemo from '../../assets/svgs/reportsCenterSvg';
import SettingSvg from '../../assets/svgs/settingSvg';
import ToolsSvg from '../../assets/svgs/toolsSvg';
import UserSvg from '../../assets/svgs/userSvg';
import { FEATURE_FLAG } from '../../globalConstants';
import { isGrowthbookFeatureEnabledByKey } from '../../utils/growthbook';
import AccountsMenu from '../navMenus/accountsMenu/accountsMenu';
import HelpMenu from '../navMenus/helpMenu/helpMenu';
import NetworkSettingsMenu from '../navMenus/networkSettingsMenu/networkSettingsMenu';
import SettingsMenu from '../navMenus/settingsMenu/settingsMenu';
import ToolsMenu from '../navMenus/toolsMenu/toolsMenu';
import UserMenu from '../navMenus/userMenu/userMenu';
import Notifications from '../notifications/notifications';
import { NAV_ITEMS } from './constants';
import { NavItemProps } from './interface';

const getAgentNavItems = (): NavItemProps[] => {
  return [
    { id: NAV_ITEMS.HOME.ID, title: NAV_ITEMS.HOME.TITLE, Icon: HomeSvg, to: NAV_ITEMS.HOME.TO_URL, index: 0 },
    {
      id: NAV_ITEMS.ACCOUNTS.ID,
      title: NAV_ITEMS.ACCOUNTS.TITLE,
      Icon: AccountsSvg,
      dropdownMenu: AccountsMenu,
      PostFixIcon: DownArrowSvg,
      index: 1,
    },
    {
      id: NAV_ITEMS.SETTINGS.ID,
      title: NAV_ITEMS.SETTINGS.TITLE,
      Icon: SettingSvg,
      dropdownMenu: SettingsMenu,
      PostFixIcon: DownArrowSvg,
      index: 2,
    },
    {
      id: NAV_ITEMS.TOOLS.ID,
      title: NAV_ITEMS.TOOLS.TITLE,
      Icon: ToolsSvg,
      dropdownMenu: ToolsMenu,
      PostFixIcon: DownArrowSvg,
      index: 3,
    },
    {
      id: NAV_ITEMS.NOTIFICATIONS.ID,
      title: NAV_ITEMS.NOTIFICATIONS.TITLE,
      Icon: Notifications,
      index: 4,
    },
    {
      id: NAV_ITEMS.HELP.ID,
      title: NAV_ITEMS.HELP.TITLE,
      Icon: HelpSvg,
      dropdownMenu: HelpMenu,
      PostFixIcon: DownArrowSvg,
      index: 5,
    },
    {
      id: NAV_ITEMS.USER.ID,
      title: NAV_ITEMS.USER.TITLE,
      Icon: UserSvg,
      dropdownMenu: UserMenu,
      PostFixIcon: DownArrowSvg,
      index: 6,
    },
  ];
};

const getNetworkNavItems = (): NavItemProps[] => {
  const isPremiumFinanceEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.PREMIUM_FINANCE_ENABLED);
  const premiumFinanceNavItem = isPremiumFinanceEnabled
    ? {
        id: NAV_ITEMS.TOOLS.ID,
        title: NAV_ITEMS.TOOLS.TITLE,
        Icon: ToolsSvg,
        dropdownMenu: ToolsMenu,
        PostFixIcon: DownArrowSvg,
        index: 4,
      }
    : {
        id: NAV_ITEMS.REPORT_CENTER.ID,
        title: NAV_ITEMS.REPORT_CENTER.TITLE,
        Icon: ReportsCenterSvgMemo,
        to: NAV_ITEMS.REPORT_CENTER.TO_URL,
        index: 4,
      };
  return [
    { id: NAV_ITEMS.HOME.ID, title: NAV_ITEMS.HOME.TITLE, Icon: HomeSvg, to: '/', index: 0 },
    {
      id: NAV_ITEMS.APPLICATIONS.ID,
      title: NAV_ITEMS.APPLICATIONS.TITLE,
      Icon: AppetiteSvg,
      to: NAV_ITEMS.APPLICATIONS.TO_URL,
      index: 1,
    },
    {
      id: NAV_ITEMS.AGENCY_SETTINGS.ID,
      title: NAV_ITEMS.AGENCY_SETTINGS.TITLE,
      Icon: SettingSvg,
      to: NAV_ITEMS.AGENCY_SETTINGS.TO_URL,
      index: 2,
    },
    {
      id: NAV_ITEMS.NETWORK_SETTINGS.ID,
      title: NAV_ITEMS.NETWORK_SETTINGS.TITLE,
      Icon: SettingSvg,
      dropdownMenu: NetworkSettingsMenu,
      PostFixIcon: DownArrowSvg,
      index: 3,
    },
    premiumFinanceNavItem,
    {
      id: NAV_ITEMS.NOTIFICATIONS.ID,
      title: NAV_ITEMS.NOTIFICATIONS.TITLE,
      Icon: Notifications,
      index: 5,
    },
    {
      id: NAV_ITEMS.HELP.ID,
      title: NAV_ITEMS.HELP.TITLE,
      Icon: HelpSvg,
      dropdownMenu: HelpMenu,
      PostFixIcon: DownArrowSvg,
      index: 6,
    },
    {
      id: NAV_ITEMS.USER.ID,
      title: NAV_ITEMS.USER.TITLE,
      Icon: UserSvg,
      dropdownMenu: UserMenu,
      PostFixIcon: DownArrowSvg,
      index: 7,
    },
  ];
};

export const getNavItems = (userType: AgentPortalUserType): NavItemProps[] => {
  switch (userType) {
    case AgentPortalUserType.AGENCY_NETWORK: {
      return getNetworkNavItems();
    }
    default:
      return getAgentNavItems();
  }
};
