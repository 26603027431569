import { logError } from './sentry';

export const getFromSessionStorage = (storageKey: string): any => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.warn('Window is not available in getFromSessionStorage');
    return null;
  }

  try {
    const stringifiedData = sessionStorage.getItem(storageKey);
    return stringifiedData ? JSON.parse(stringifiedData) : null;
  } catch (err) {
    logError({ error: `Error in getFromSessionStorage for key: ${storageKey}, and error: ${JSON.stringify(err)}` });
    return null;
  }
};
