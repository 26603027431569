import { AccountBookOutlined, DollarOutlined, ReadOutlined } from '@ant-design/icons';

import { FEATURE_FLAG, PAGE_ROUTES } from '../../../globalConstants';
import { isGrowthbookFeatureEnabledByKey } from '../../../utils/growthbook';

export const getAccountsMenuElements = () => {
  const isPremiumFinanceEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.PREMIUM_FINANCE_ENABLED);
  const accountsMenuElements = [
    { icon: AccountBookOutlined, to: PAGE_ROUTES.ACCOUNTS, label: 'By Accounts' },
    { icon: ReadOutlined, to: PAGE_ROUTES.APPLICATIONS, label: 'By Applications' },
  ];
  const premiumFinanceElement = { icon: DollarOutlined, to: PAGE_ROUTES.PF_APPLICATIONS, label: 'Premium Financing' };
  if (isPremiumFinanceEnabled) {
    accountsMenuElements.push(premiumFinanceElement);
  }

  return accountsMenuElements;
};
