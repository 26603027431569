import styled from 'styled-components/macro';

import CFDrawer from '../../ui-core/V2/cfDrawer/cfDrawer';

export const BiBerkAppointmentDrawerStyled = styled(CFDrawer)``;

export const BiBerkAppointmentIframeStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;
