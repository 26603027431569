import { ICarrierStatementGroup } from '@coverforce-platform/cf-common-api-model';
import { StatementType } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUWStatements } from '../../../externalServices/V2/applicationV2';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { BIND_UW_STATEMENT_STORE_META_DATA } from './constants';
import { IBindUWStatementModal } from './interface';

export const useBindUWStatementStore = create<IBindUWStatementModal>()(
  devtools<IBindUWStatementModal>(
    (set) => ({
      bindUWStatementLoading: false,
      bindUWStatementData: undefined,

      fetchBindUWStatements: async ({ bindUWStatementLoading = true }) => {
        const { applicationData } = useApplicationStore.getState();

        const underWritingStatement: { [key: string]: ICarrierStatementGroup } = {};

        set({ bindUWStatementLoading }, false, BIND_UW_STATEMENT_STORE_META_DATA.BIND_UW_STATEMENT_LOADING);

        const response = await getUWStatements(
          { applicationId: applicationData?.applicationId || '' },
          { type: StatementType.BIND },
        );

        response?.disclosures?.map((el) => {
          underWritingStatement[el?.carrier] = el;
        });

        set(
          { bindUWStatementLoading: false, bindUWStatementData: underWritingStatement },
          false,
          BIND_UW_STATEMENT_STORE_META_DATA.FETCHED_BIND_UW_STATEMENT_DATA,
        );

        return underWritingStatement;
      },

      clearBindUWStatementStore: () => {
        set(
          { bindUWStatementLoading: false, bindUWStatementData: undefined },
          false,
          BIND_UW_STATEMENT_STORE_META_DATA.CLEAR_BIND_UW_STATEMENT_STORE,
        );
      },
    }),
    {
      name: BIND_UW_STATEMENT_STORE_META_DATA.STORE_NAME,
      anonymousActionType: BIND_UW_STATEMENT_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
    },
  ),
);
