import {
  ICreatePFQuoteInput,
  ICreatePFQuoteOutput,
  IDeletePFApplicationInput,
  IDownloadPremiumFinanceQuoteAgreementInput,
  IDownloadPremiumFinanceQuoteAgreementOutput,
  IGetPFApplicationsByAgencyOrNetworkInput,
  IGetPFApplicationsByAgencyOrNetworkOutput,
  IGetPFLoginConfigsByAgencyOrNetworkInput,
  IGetPFLoginConfigsByAgencyOrNetworkOutput,
  IGetQuotesForPFApplicationInput,
  IGetQuotesForPFApplicationOutput,
  IInitiateESignInput,
  IInitiateESignOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosAccountService from '../../axios/accountService/instance';
import axiosPremiumFinanceService from '../../axios/pfApplicationService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { PREMIUM_FINANCE_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

export const getAgencyNetworkPFApplications = async (
  params: IGetPFApplicationsByAgencyOrNetworkInput,
): Promise<IGetPFApplicationsByAgencyOrNetworkOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.GET_ALL_PF_APPLICATION,
    params,
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const getPFLoginConfig = async (
  params: IGetPFLoginConfigsByAgencyOrNetworkInput,
): Promise<IGetPFLoginConfigsByAgencyOrNetworkOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.GET_PF_LEGAL_CONFIG(params.agencyOrNetworkId),
  });
  try {
    const response: AxiosResponse = await axiosAccountService.get(apiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const createPFApplication = async (params: ICreatePFQuoteInput): Promise<ICreatePFQuoteOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.CREATE_PF_APPLICATION,
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.post(apiUrl, params);

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const fetchPFApplication = async (
  params: IGetQuotesForPFApplicationInput,
): Promise<IGetQuotesForPFApplicationOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.GET_PF_APPLICATION_BY_ID(params.pfApplicationId),
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.get(apiUrl);

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const eSignPFQuote = async (params: IInitiateESignInput): Promise<IInitiateESignOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.START_ESIGN_PF_QUOTE(params.pfQuoteId),
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.post(apiUrl);

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const downloadPFQuoteAgreement = async (
  params: IDownloadPremiumFinanceQuoteAgreementInput,
): Promise<IDownloadPremiumFinanceQuoteAgreementOutput> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.DOWNLOAD_PF_QUOTE_AGREEMENT(params.pfQuoteId),
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.get(apiUrl);

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export const deletePfApplication = async (params: IDeletePFApplicationInput): Promise<void> => {
  const apiUrl = createQueryUrl({
    url: PREMIUM_FINANCE_SERVICE_ENDPOINTS.DELETE_PF_APPLICATION(params.pfApplicationId),
  });
  try {
    const response: AxiosResponse = await axiosPremiumFinanceService.delete(apiUrl);

    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};
