import { IPFApplicationInfoQuestionsStore } from './interface';

export const PF_APPLICATION_INFO_QUESTIONS_ACTIONS = {
  SET_PF_APP_INFO_FORM: 'SET_PF_APP_INFO_FORM',
  SET_PF_LOGIN_CONFIGS: 'SET_PF_LOGIN_CONFIGS',
  SET_PF_SUPPORTED_CARRIERS_LOADING: 'SET_PF_SUPPORTED_CARRIERS_LOADING',
  SET_PF_LOGIN_CONFIGS_ERROR: 'SET_PF_LOGIN_CONFIGS_ERROR',
  SET_PF_SUPPORTED_CARRIERS: 'SET_PF_SUPPORTED_CARRIERS',
  ANONYMOUS_ACTION: 'PF_APPLICATION_INFO_QUESTIONS_ANONYMOUS_ACTION',
  STORE_NAME: 'PF_APPLICATION_INFO_QUESTIONS_STORE',
  UPDATE_PF_APPLICATION_INFO_BY_KEY: 'UPDATE_PF_APPLICATION_INFO_BY_KEY',
  CLEAR_STORE: 'CLEAR_PF_APPLICATION_INFO_QUESTIONS_STORE',
};

export const PF_APPLICATION_INFO_STORE_KEY: { [k: string]: keyof IPFApplicationInfoQuestionsStore } = {
  PF_APPLICATION_INFO_QUESTIONS_ELEMENTS: 'pfApplicationInfoQuestionsElements',
  PF_APPLICATION_INFO_LOADING: 'pfApplicationInfoLoading',
  PF_APPLICATION_INFO_QUESTIONS_FORM: 'pfApplicationInfoQuestionsForm',
};

export const SUPPORT_CARRIER_FETCH_ERROR = 'Something went wrong while fetching supported carriers.';

export const PF_APPLICATION_INFO_QUESTION_DATA_INDEX = {
  EMAIL: 'insuredDetails.email',
  PHONE: 'insuredDetails.phone',
  NAME: 'insuredDetails.name',
  CARE_OF: 'insuredDetails.careOf',
  PRIMARY_ADDRESS: 'insuredDetails.address',
  EFFECTIVE_DATE: 'policyDetails.effectiveDate',
  EXPIRATION_DATE: 'policyDetails.expirationDate',
  CARRIER: 'policyDetails.carrier',
  POLICY_TYPE: 'policyDetails.policyType',
  PREMIUM: 'policyDetails.premium',
  POLICY_NUMBER: 'policyDetails.policyNumber',
  TAX: 'policyDetails.tax',
  FEE: 'policyDetails.fee',
  INVOICE_NUMBER: 'policyDetails.invoiceNumber',
  DOWN_PAYMENT_METHOD: 'pfDetails.downPaymentMethod',
  DOWN_PAYMENT_BY: 'pfDetails.downPaymentPaidBy',
  DOWN_PAYMENT_PROCESS: 'pfDetails.processDownPayment',
  STATEMENT_DECLARATION: 'statementDeclaration',
  UW_COMPANY_DETAILS_UNIQUE_ID: 'policyDetails.uwCompanyDetailsUniqueId',
};

export const PF_APPLICATION_INFO_QUESTIONS_ACCORDION_ID: { [k: string]: number } = {
  INSURED_DETAILS: 0,
  POLICY_DETAILS: 1,
  PREMIUM_FINANCE_DETAILS: 2,
  DECLARATION_STATEMENTS: 3,
};

export const PF_STATEMENT_DECLARATION_TEXT = [
  'Carrier must acknowledge the policy is being premium financed.',
  'Carrier must accept premium finance notices (i.e. cancellation requests) and honor the date of requested.',
  'All return premiums must be issued to IPFS (not the insured).',
];
