import { Col, Row } from 'antd';
import styled from 'styled-components/macro';

import CFCheckbox, { CFCheckboxGroup } from '../../../ui-core/V2/cfCheckbox/cfCheckbox';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';

export const QuoteComparisonModalStyled = styled(CFModal)``;

export const QuoteComparisonTextWrapperStyled = styled.div`
  display: flex;
  font-weight: ${(p) => p.theme?.typography?.fontWeight?.medium};
  margin-bottom: 10px;
`;

export const QuoteComparisonTextStyled = styled.div`
  margin-right: 5px;
`;

export const QuoteComparisonCheckboxGroupStyled = styled(CFCheckboxGroup)`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  label {
    padding: 3px 0px;
  }

  .ant-checkbox-wrapper:hover {
    background-color: ${(p) => p?.theme?.custom?.fileContainer?.main}80;
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.custom?.fileContainer?.main}80;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
`;

export const QuoteComparisonCheckboxRowStyled = styled(Row)`
  justify-content: space-between;
`;

export const QuoteComparisonColStyled = styled(Col)<{ isChecked?: boolean }>`
  .ant-checkbox-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border: 1px solid
      ${(p) => (p?.isChecked ? p?.theme?.custom?.fileContainer?.main : p?.theme?.colors?.primary?.border)};
    border-radius: 8px;
    background-color: ${(p) => p?.isChecked && `${p?.theme?.custom?.fileContainer?.main}80`};
    min-height: 60px;
  }
`;

export const QuoteComparisonCheckboxStyled = styled(CFCheckbox)``;

export const QuoteComparisonCheckboxItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const QuoteComparisonCheckboxLabelWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteComparisonCarrierStyled = styled.div`
  font-weight: ${(p) => p.theme?.typography?.fontWeight?.medium};
`;

export const QuoteComparisonPremiumStyled = styled.div`
  color: ${(p) => p?.theme?.colors?.application?.green};
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extraMini};
`;
